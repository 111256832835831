import axios from "axios";
import { TYPE } from "./types";
import * as Constants from "../../constants";

export function getAllPromotions(data) {
  let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
  let loginApi = Constants.SUPER_ADMIN_API + "/" + TYPE.GET_PROMOTIONS;
  const request = axios
    .post(loginApi, data, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
    .then((response) => response.data);
  return {
    type: TYPE.GET_PROMOTIONS,
    payload: request,
  };
}

export function editPromotion(data) {
  let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
  let loginApi = Constants.SUPER_ADMIN_API + "/" + TYPE.EDIT_PROMOTIONS;
  const request = axios
    .post(loginApi, data, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
    .then((response) => response.data);
  return {
    type: TYPE.EDIT_PROMOTIONS,
    payload: request,
  };
}


export function getPromotionIdDetails(data) {
  let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
  let loginApi = Constants.SUPER_ADMIN_API + "/" + TYPE.GET_PROMOTIONS_ID_DETAILS;
  const request = axios
    .post(loginApi, data, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
    .then((response) => response.data);
  return {
    type: TYPE.GET_PROMOTIONS_ID_DETAILS,
    payload: request,
  };
}


export function insertNewPromotion(data) {
  let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
  let loginApi = Constants.SUPER_ADMIN_API + "/" + TYPE.INSERT_PROMOTION;
  const request = axios
    .post(loginApi, data, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
    .then((response) => response.data);
  return {
    type: TYPE.INSERT_PROMOTION,
    payload: request,
  };
}

export function updatePromotion(data) {
  let apiToken = JSON.parse(localStorage.getItem(Constants.USER_DATA)).token;
  let loginApi = Constants.SUPER_ADMIN_API + "/" + TYPE.UPDATE_PROMOTION;
  const request = axios
    .post(loginApi, data, {
      headers: { Authorization: `Bearer ${apiToken}` },
    })
    .then((response) => response.data);
  return {
    type: TYPE.UPDATE_PROMOTION,
    payload: request,
  };
}
