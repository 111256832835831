import React from "react";
import { Modal, Image } from "semantic-ui-react";
import adminImages from "../assets";
export default function ResponsePopup(props) {
  return (
    <Modal
      className="response-popup"
      open={props.isVisiblePopup}
      onOpen={() => props.setIsVisiblePopup(true)}
      onClose={() => props.setIsVisiblePopup(false)}
      // center={true}
    >
      <Modal.Content
        style={{
          borderRadius: "0.75rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection:"column",
          height: "100%",
          width: "100%",
          padding:"0rem",
        }}
      >
         {props.showActionButton && (
            <div
              style={{
                height: "100%",
                width:"100%",
                display: "flex",
                justifyContent: "end",
                alignItems: "start",
                padding:"0.5rem"
              }}
            >
              <svg
              onClick={() => props.onCloseAction()}
              style={{cursor:"pointer"}}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5ZM5.52003 6.713C5.9252 6.23693 6.63958 6.17945 7.11564 6.58461L12.632 11.2794L18.1484 6.58469C18.6244 6.17953 19.3388 6.237 19.744 6.71307C20.1491 7.18914 20.0917 7.90352 19.6156 8.30869L14.3785 12.7658L19.6156 17.2229C20.0917 17.6281 20.1492 18.3425 19.744 18.8185C19.3389 19.2946 18.6245 19.3521 18.1484 18.9469L12.632 14.2521L7.11559 18.947C6.63952 19.3521 5.92514 19.2947 5.51998 18.8186C5.11481 18.3425 5.17229 17.6282 5.64836 17.223L10.8856 12.7658L5.64841 8.30861C5.17235 7.90344 5.11487 7.18906 5.52003 6.713Z"
                  fill="#818181"
                />
              </svg>
            </div>
          )}
        <div style={{height:"100%", display:"flex", flexDirection:"column",padding:"1rem"}}>
         
          <Image
            className="response-illustration"
            src={
              props.isErrorPopup
                ? adminImages.errorIllustration
                : adminImages.successIllustration
            }
          />
          <p
            className="text bold"
            style={{
              textAlign: "center",
              color: props.isErrorPopup ? "red" : "green",
              fontSize: "1.25rem",
              marginBlock: "1rem",
            }}
          >
            {props.isErrorPopup ? "ERROR" : "SUCCESS"}
          </p>

          <p
            className="text bold"
            style={{ textAlign: "center", fontSize: "1.125rem" }}
          >
            {props.popupMsg}
          </p>
        </div>
      </Modal.Content>
    </Modal>
  );
}
