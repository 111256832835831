import { TYPE } from "../actions/types";
const initialState = {
    uploadChequeResponse: {}
};

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.UPLOAD_CHEQUE:
            state.uploadChequeResponse = response
            return {
                ...state, response: response
            }
        case "CLEAR_UPLOAD_CHEQUE_RESPONSE":
            state.uploadChequeResponse = {}
            return {
                ...state, response: response
            }
        default:
            return state;
    }

}