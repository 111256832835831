import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Table,
    Segment,
    Grid,
    Sidebar,
    Button,
    Input,
    Dropdown,
    Menu,
    Label,
    Icon,
    Loader,
    Checkbox,
    Modal,
    Form,
    Popup,
    Image, Breadcrumb, Divider
} from "semantic-ui-react";
import {
    USER_DATA,
    SELECTED_HOTEL_ID,
    SELECTED_HOTEL_NAME
} from "../../../constants";
import { logout } from "../../../redux/actions/user_actions";
import { clearSession } from "../../../redux/actions/session_actions";

import MenuListMobile from "./menuItem_m"
import CustomSideNavBar from "./customSideNavBar"


function CustomHeaderComponent(props) {
    const [sidebarVal, setSideBarVal] = useState(false)

    const closeSideNavBar = () => {
        setSideBarVal(false)
        document.body.style.overflow = "auto"
    }

    const showSideNavBar = () => {
        setSideBarVal(true)
        document.body.style.overflow = "hidden"
    }


    return (
        <div style={{ display: "flex", flexDirection: 'row', alignItems: "center",}}>
            <svg style={{ cursor: "pointer", }} onClick={() => showSideNavBar()} className="side_bars_icon" viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 20H45.6" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
                <path d="M29.5996 36L45.5996 36" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
                <path d="M4 4L20 4" stroke="#303030" strokeWidth="6.4" strokeLinecap="round" />
            </svg>

            <CustomSideNavBar {...props} sidebarVal={sidebarVal} showSideNavBar={showSideNavBar} closeSideNavBar={closeSideNavBar} />

            <div>
                <h1 className="screen_name_cls" style={{ color: "black", fontWeight: 500, fontFamily: "Montserrat" }}>
                    {props.screenName}
                </h1>
            </div>

        </div>
        // </div>
    );
}
export default CustomHeaderComponent;
