import { TYPE } from "../actions/types";
const initialState = {
    apiResponse: {},
    isLoading: true,
    pendingRequests: [],
    rejectRequests: [],
    approvedRequests: []
};

export default function (state = initialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.ADMIN_HOME:
            state.apiResponse = response
            state.isLoading = false

            if (response.status === "SUCCESS") {
                if (response.pending_requests !== undefined) state.pendingRequests = response.pending_requests
                if (response.approved_requests !== undefined) state.approvedRequests = response.approved_requests
                if (response.rejected_requests !== undefined) state.rejectRequests = response.rejected_requests
            }

            return {
                ...state, response: response
            }
        case "CLEAR_HOME_PAGE_RESPONSES":
            state.apiResponse = {}
            state.isLoading = true
            state.pendingRequests = []
            state.rejectRequests = []
            state.approvedRequests = []
            return {
                ...state
            }

        default:
            return state;
    }

}