import { TYPE } from "../actions/types";

const intialState = {
  getAllPromotionsRes: {},
  editPromotionsRes: {},
  getPromotionIdDetails: {},
  insertPromotionRes:{},
  updatePromotionRes:{}
};

export default function (state = intialState, action) {
  switch (action.type) {
    case TYPE.GET_PROMOTIONS:
      state.getAllPromotionsRes = action.payload;
      return { ...state, response: action.payload };
    
    case TYPE.GET_PROMOTIONS_ID_DETAILS:
      state.getPromotionIdDetails = action.payload;
      return { ...state, response: action.payload };
    case TYPE.INSERT_PROMOTION:
    state.insertPromotionRes = action.payload;
    return { ...state, response: action.payload };

    case TYPE.UPDATE_PROMOTION:
      state.updatePromotionRes = action.payload;
      return { ...state, response: action.payload };

    case "clear_update_promotion_res":{
      state.updatePromotionRes = {};
    }
    case "clear_get_all_promotions_res": {
      state.getAllPromotionsRes = {};
    }
    case "clear_get_promotion_id_details": {
      state.getPromotionIdDetails = {};
    }
    case "clear_insert_promotion_res": {
      state.insertPromotionRes = {};
    }
    default:
      return state;
  }
}
