import React, { useEffect, useState, useRef } from "react";
import adminImages from "../assets";
import moment from "moment";
import { Grid, Image } from "semantic-ui-react";

export default function MultipleDateCustomCalendar(props) {
  const [dateYears, setDateYears] = useState(moment().format("YYYY"));
  const [dateMonths, setDateMonths] = useState(moment().format("MMMM"));
  const currentDateMonth = moment().subtract(1,"d").format("MMMM");
  const currentDateYear = moment().subtract(1,"d").format("YYYY");
  const currentDateDay = moment().subtract(1,"d").format("DD");

  const [openYearsDropDown, setOpenYearsDropdDown] = useState(false);
  const [openMonthsDropDown, setOpenMonthsDropDown] = useState(false);
  let calenderRef = useRef();
  let monthsRef = useRef();
  let yearsRef = useRef();

  useEffect(() => {
    if (props.showCalendar) {
      document.body.style.overflow = "hidden";
      if (calenderRef.current) {
        const modalRect = calenderRef.current.getBoundingClientRect();
        const isModalGoingOutside = modalRect.bottom > window.innerHeight;

        if (isModalGoingOutside) {
          calenderRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      }
      return () => (document.body.style.overflowY = "scroll");
    }
  }, [props.showCalendar]);

  useEffect(() => {
    let handler = (e) => {
      if (openMonthsDropDown) {
        if (!monthsRef.current.contains(e.target)) {
          setOpenMonthsDropDown(false);
        }
      } else if (openYearsDropDown) {
        if (!yearsRef.current.contains(e.target)) {
          setOpenYearsDropdDown(false);
        }
      } else if (props.open) {
        if (!calenderRef.current.contains(e.target)) {
          props.setOpen(false);
          props.setShowCalendar(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const mappedMonth = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  //   const resetDate = () => {
  //     if (props.dates == null) {
  //       setDateDay(moment().format("DD"));
  //       setDateMonth(moment().format("MMMM"));
  //       setDateYear(moment().format("YYYY"));
  //     } else {
  //       setDateDay(moment(props.dates).format("DD"));
  //       setDateMonth(moment(props.dates).format("MMMM"));
  //       setDateYear(moment(props.dates).format("YYYY"));
  //     }
  //   };

  const years = () => {
    let yearsArr = [];
    for (let i = 2016; i < parseInt(moment().format("YYYY")) + 4; i++) {
      yearsArr.push(i);
    }
    return yearsArr;
  };

  //   const onSelectYear = (value, type) => {
  //     setDateYear(value);
  //     setOpenYearsDropdDown(false);
  //     setDateDay(1);
  //   };

  //   const onSelectMonth = (value, type) => {
  //     setDateMonth(value);
  //     setOpenMonthsDropDown(false);
  //     setDateDay(1);
  //   };

  const openDropDown = (type) => {
    if (type === "dateYear") {
      setOpenYearsDropdDown(!openYearsDropDown);
      setOpenMonthsDropDown(false);
    } else if (type === "dateMonths") {
      setOpenMonthsDropDown(!openMonthsDropDown);
      setOpenYearsDropdDown(false);
    }
  };

  const generateCalenderHeader = () => {
    let layout = [];

    layout.push(
      <div className="calendar-header-view">
        <div
          className="flex-center"
          style={{
            cursor:
              dateYears == currentDateYear &&
              mappedMonth[currentDateMonth] >= mappedMonth[dateMonths]
                ? "default"
                : "pointer",
          }}
          onClick={() =>
            dateYears == currentDateYear &&
            mappedMonth[currentDateMonth] >= mappedMonth[dateMonths]
              ? null
              : onChangeMonth("previous")
          }
        >
          <svg
            style={{ marginInline: "0.625rem" }}
            width="15"
            height="18"
            viewBox="0 0 15 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 2.66669L2.84886 12.3178C2.38402 12.7827 2.39483 13.5396 2.87276 13.991L12.5 23.0834"
              stroke={
                dateYears == currentDateYear &&
                mappedMonth[currentDateMonth] >= mappedMonth[dateMonths]
                  ? "#818181"
                  : "#303030"
              }
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="flex-center" style={{ width: "100%", gap: "1rem" }}>
          <p
            className="dateOptionsText"
            style={{
              color: "#1c1c1c",
              marginBottom: "0",
            }}
          >
            {dateMonths}
          </p>
          <p
            className="dateOptionsText"
            style={{
              color: "#1c1c1c",
            }}
          >
            {dateYears}
          </p>
        </div>
        <div
          className="flex-center"
          style={{
            cursor:
              Number(currentDateYear) + 1 == Number(dateYears) &&
              mappedMonth[currentDateMonth] <= mappedMonth[dateMonths]
                ? "default"
                : "pointer",
          }}
          onClick={() =>
            Number(currentDateYear) + 1 == Number(dateYears) &&
            mappedMonth[currentDateMonth] <= mappedMonth[dateMonths]
              ? null
              : onChangeMonth("next")
          }
        >
          <svg
            style={{ marginInline: "0.625rem" }}
            width="15"
            height="18"
            viewBox="0 0 15 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66602 2.16663L12.3172 11.8178C12.782 12.2826 12.7712 13.0395 12.2933 13.4909L2.66602 22.5833"
              stroke={
                Number(currentDateYear) + 1 == Number(dateYears) &&
                mappedMonth[currentDateMonth] <= mappedMonth[dateMonths]
                  ? "#818181"
                  : "#303030"
              }
              strokeWidth="3.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    );

    return layout;
  };
  const generateWeekLabels = () => {
    let layout = [];

    layout.push(
      <Grid
        columns={7}
        style={{ paddingInline: "1.5rem", marginTop: "1.25rem" }}
      >
        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Mo</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Tu</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">We</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Th</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Fr</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Sa</h3>
        </div>

        <div className="flex-center" style={{ width: "14%" }}>
          <h3 className="weekLabels">Su</h3>
        </div>
      </Grid>
    );

    return layout;
  };
  const generateFromDateDays = () => {
    let startDayOfMonth = moment(`${dateYears}-${dateMonths}-01`).format(
      "dddd"
    );

    let startDayOfMonthValue = null;
    let currentDate = moment().format("D");
    let endOfTheMonth = parseInt(
      moment(`${dateYears}-${dateMonths}-01`).endOf("month").format("DD")
    );

    const weekdays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    for (let i = 1; i < weekdays.length; i++) {
      if (startDayOfMonth == weekdays[i]) {
        startDayOfMonthValue = i;
      }
    }

    let fromDateDaysLayout = [];
    for (let i = 0; i < 42; i++) {
      if (i >= startDayOfMonthValue) {
        if (endOfTheMonth >= i - startDayOfMonthValue + 1) {
          fromDateDaysLayout.push(
            <div
              onClick={() =>
                mappedMonth[dateMonths] == mappedMonth[currentDateMonth] &&
                ((dateYears == currentDateYear &&
                  mappedMonth[dateMonths] == mappedMonth[currentDateMonth] &&
                  i - startDayOfMonthValue + 1 < currentDateDay) ||
                  (Number(dateYears) == Number(currentDateYear) + 1 &&
                    i - startDayOfMonthValue + 1 > currentDateDay))
                  ? null
                  : onSelectDate(i - startDayOfMonthValue + 1)
              }
              tabIndex="0"
              className={`flex-center ${
                props.dates.includes(
                  moment(
                    `${dateYears}-${dateMonths}-${i - startDayOfMonthValue + 1}`
                  ).format("YYYY-MM-DD")
                )
                  ? "multiDateselectedDay"
                  : mappedMonth[dateMonths] == mappedMonth[currentDateMonth] &&
                    ((dateYears == currentDateYear &&
                      mappedMonth[dateMonths] ==
                        mappedMonth[currentDateMonth] &&
                      i - startDayOfMonthValue + 1 < currentDateDay) ||
                      (Number(dateYears) == Number(currentDateYear) + 1 &&
                        i - startDayOfMonthValue + 1 > currentDateDay))
                  ? "multiDatedisableDayLabels"
                  : "multiDatedaysLabel"
              }`}
              style={{ height: "3.2rem", borderRadius: "50%", width: "14%" }}
            >
              <h3
                className="text bold"
                style={{
                  cursor:
                    mappedMonth[dateMonths] == mappedMonth[currentDateMonth] &&
                    ((dateYears == currentDateYear &&
                      mappedMonth[dateMonths] ==
                        mappedMonth[currentDateMonth] &&
                      i - startDayOfMonthValue + 1 < currentDateDay) ||
                      (Number(dateYears) == Number(currentDateYear) + 1 &&
                        i - startDayOfMonthValue + 1 > currentDateDay))
                      ? "default"
                      : "pointer",
                  color: props.dates.includes(
                    moment(
                      `${dateYears}-${dateMonths}-${
                        i - startDayOfMonthValue + 1
                      }`
                    ).format("YYYY-MM-DD")
                  )
                    ? "#fff"
                    : mappedMonth[dateMonths] ==
                        mappedMonth[currentDateMonth] &&
                      ((dateYears == currentDateYear &&
                        mappedMonth[dateMonths] ==
                          mappedMonth[currentDateMonth] &&
                        i - startDayOfMonthValue + 1 < currentDateDay) ||
                        (Number(dateYears) == Number(currentDateYear) + 1 &&
                          i - startDayOfMonthValue + 1 > currentDateDay))
                    ? "#818181"
                    : "",
                  fontSize: "1.125rem",
                }}
              >
                {i - startDayOfMonthValue + 1}
              </h3>
            </div>
          );
        }
      } else {
        fromDateDaysLayout.push(
          <div
            className="flex-center"
            style={{ height: "3.2rem", borderRadius: "50%", width: "14%" }}
          ></div>
        );
      }
    }

    return fromDateDaysLayout;
  };

  const onOpenCalender = () => {
    if (!props.isLoading) {
      //   resetDate();
      props.setShowCalendar(true);
      props.setOpen(true);
    }
  };

  const closeModal = () => {
    props.setOpen(false);
    props.setShowCalendar(false);
  };

  const onChangeMonth = (type) => {
    let currentStateValueIndex = months.indexOf(dateMonths);
    if (type === "previous") {
      if (currentStateValueIndex !== 0) {
        setDateMonths(months[currentStateValueIndex - 1]);
      } else {
        setDateMonths(months[11]);
        setDateYears(parseInt(dateYears) - 1);
      }
    } else {
      if (currentStateValueIndex !== 11) {
        setDateMonths(months[currentStateValueIndex + 1]);
      } else {
        setDateMonths(months[0]);
        setDateYears(parseInt(dateYears) + 1);
      }
    }
  };

  const onSelectDate = (date) => {
    if (
      props.dates.includes(
        moment(`${dateYears}-${dateMonths}-${date}`).format("YYYY-MM-DD")
      )
    ) {
      props.setDates((prevState) => {
        return prevState.filter(
          (item) =>
            item !==
            moment(`${dateYears}-${dateMonths}-${date}`).format("YYYY-MM-DD")
        );
      });
    } else {
      props.setDates((prevState) => [
        moment(
          `${dateYears}-${
            months.indexOf(dateMonths) < 9
              ? `0${months.indexOf(dateMonths) + 1}`
              : months.indexOf(dateMonths) + 1
          }-${date < 10 ? `0${date}` : date}`
        ).format("YYYY-MM-DD"),
        ...prevState,
      ]);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="flex-start" style={{ gap: "0.5rem" }}>
        {props.dateBox ? (
          props.dateBox
        ) : (
          <div
            className="cp_calenderBlock"
            style={{height:"2.5rem"}}
            onClick={() => (props.isLoading ? null : onOpenCalender())}
          >
            <h3
              className="p_text p_text_md p_text_bold"
              style={{
                color:
                  props.dates === null ||
                  props.dates === undefined ||
                  props.dates === ""
                    ? "#818181"
                    : "",
              }}
            >
              {props.dates === null ||
              props.dates === undefined ||
              props.dates === ""
                ? "YYYY-MM-DD"
                : `${moment(props.dates).format("MMM")} ${moment(
                    props.dates
                  ).format("DD")},${moment(props.dates).format("YYYY")}`}
            </h3>
          </div>
        )}
      </div>
      {props.showCalendar ? (
        <div className="modalView" onClick={() => closeModal()} />
      ) : null}
      <div style={{ display: "flex", justifyContent: "start" }}>
        {props.open ? (
          <div
            ref={calenderRef}
            className="date-view cp"
            style={{ marginTop: "1rem" }}
          >
            {generateCalenderHeader()}

            {generateWeekLabels()}

            <Grid
              columns={7}
              style={{
                paddingInline: "1.6rem",
                paddingBlock: "1rem",
              }}
            >
              {generateFromDateDays()}
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <p
                onClick={closeModal}
                className="p_text p_text_sm p_text_bold"
                style={{
                  color: "#818181",
                  cursor: "pointer",
                  marginInline: "0.5rem",
                }}
              >
                Close
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
