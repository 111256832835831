import { combineReducers } from "redux";
import userResponse from "./user_reducer";
import bookingResponse from "./booking_reducer";
import availabilityResponse from "./availability_reducer";
import rateResponse from "./rates_reducer";
import roomResponse from "./rooms_reducer";
import session from "./session_reducer";
import hotelProfile from "./profile_reducer";
import settingUpdate from "./sa_reducer";
import amentiesResponse from "./amenties_reducers";
import adminDashboardResponse from "./admin_dashboard_reducer";
import bookingCountResponse from "./admin_dashboard_reducer";
import policyUpdateResponse from "./policy_reducer";
import checkinResponse from "./checkIn_reducers";
import updateCheckinResponse from "./checkIn_reducers"
import userAccessResponse from "./userAccess_reducer"
import hotelListingResponse from "./hotel_listing_reducer"
import bankAccountResponse from "./bank_account_reducer";
import adminResponse from "./admin_reducer"
import hotelFinanceResponse from "./finance_reducer";
import userPermissionResponse from "./user_extranet_reducer";
import marketingResponse from './marketing_reducer';
import bucketResponse from './bucket_reducer';
// import oldSEOResponse from "./seo_reducer";
import seoResponse from "./Holida_Brevi_SEO_reducer";
import siteUserResponse from './site_users_reducers'
import userDetailResponse from './user_details_reducer'
import experiencesResponse from './experiences_reducers'
import analyticsResponse from './analytics_reducer'
import hotelListWithPopularityResponse from "./edit_popularity_reducer";
import restrictedCouponResponse from "./restricted_coupon_reducer"
import campaignsResponse from "./campaigns_reducer"
import commissionsResponse from "./commissions_reducer";
import refundPoliciesResponse from "./refund_policies_reducer"
import escalationResponse from "./escalation_reducer";
import reviewResponse from "./review_reducer";
import profileCommentDataResponse from "./profile_comment_reducer";
import systemAdminRes from "./system_admin_reducer";
import roomCategoryResponse from "./room_category_reducer"
import fulldayAmenityResponse from "./full_day_amenities_reducer";
import fulldayInventoryResponse from "./full_day_inventory_reducers";
import holidaBookingsDashboardResponse from "./holidaDashboard_reducer";
import PromotionResponse from "./promotions_reducers";
import cashbackConfigResponse from "./cashback_module_reducer";
import downloadBookingResponse from "./download_excel_reducer";
import getSoldOutResponse from "./get_sold_out_hotel_reducer";
import getRoomPlanRefundPolicyRes from "./get_dummy_refund_policy_reducer";
import nearByHotelsResponse from "./nearBy_hotels_reducer";
import channelManagerResponse from "./channel_manager_reducer"
import createAdditionalPolicy from "./additional_policy_reducer";
import updateAdditionalPolicy from "./additional_policy_reducer";
import removeAdditionalPolicy from "./additional_policy_reducer";
import createUpdateCronDetails from "./cron_manager_reducer";
import getCronDetails from "./cron_manager_reducer";
import getCronLogs from "./cron_manager_reducer";
import getPlatformChargeAndStatus from "./platform_charge_reducer";
import updatePlatformChargeData from "./platform_charge_reducer";
import updatePlatformChargeStatus from "./platform_charge_reducer";
import homePageApiResponse from "./home_reducer";
import propertyAccountsResponse from "./propert_accounts_reducer"

const rootReducer = combineReducers({
  nearByHotelsResponse,
  getRoomPlanRefundPolicyRes,
  getSoldOutResponse,
  downloadBookingResponse,
  cashbackConfigResponse,
  systemAdminRes,
  PromotionResponse,
  fulldayInventoryResponse,
  fulldayAmenityResponse,
  profileCommentDataResponse,
  reviewResponse,
  userResponse,
  bookingResponse,
  availabilityResponse,
  escalationResponse,
  rateResponse,
  roomResponse,
  session,
  hotelProfile,
  settingUpdate,
  amentiesResponse,
  adminDashboardResponse,
  bookingCountResponse,
  policyUpdateResponse,
  checkinResponse,
  updateCheckinResponse,
  userAccessResponse,
  hotelListingResponse,
  bankAccountResponse,
  adminResponse,
  hotelFinanceResponse,
  userPermissionResponse,
  marketingResponse,
  bucketResponse,
  seoResponse,
  siteUserResponse,
  userDetailResponse,
  experiencesResponse,
  analyticsResponse,
  hotelListWithPopularityResponse,
  restrictedCouponResponse,
  campaignsResponse,
  commissionsResponse,
  refundPoliciesResponse,
  roomCategoryResponse,
  holidaBookingsDashboardResponse,
  channelManagerResponse,
  createAdditionalPolicy,
  updateAdditionalPolicy,
  removeAdditionalPolicy,
  createUpdateCronDetails,
  getCronDetails,
  getCronLogs,
  getPlatformChargeAndStatus,
  updatePlatformChargeData,
  updatePlatformChargeStatus,
  homePageApiResponse,
  propertyAccountsResponse

});

export default rootReducer;
