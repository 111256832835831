import { TYPE } from '../actions/types';
const intialState ={
    cacheAndSecretRes:{},
    clearCacheRes:{},
    editContactNoAndMsgRes:{},
    getContactNoAndMsgRes:{},
    getTaxValueRes:{},
    editTaxValueRes:{},
    updateOtaListRes:{},
    getOtaListRes:{},
}

export default function (state=intialState, action) {

    switch (action.type) {
        case TYPE.GET_CACHE_AND_SECRET:
            state.cacheAndSecretRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.CLEAR_CACHE:
            state.clearCacheRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.GET_CONTACT_NUMBER_AND_MSG:
            state.getContactNoAndMsgRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.EDIT_CONTACT_NUMBER_AND_MSG:
            state.editContactNoAndMsgRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.GET_TCS_PERCENTAGE:
            state.getTaxValueRes = action.payload
            return {...state, response: action.payload}
        case TYPE.EDIT_TCS_PERCENTAGE:
            state.editTaxValueRes = action.payload
            return {...state, response: action.payload}
        case TYPE.GET_OTA_LIST:
            state.getOtaListRes=action.payload
            return { ...state, response: action.payload }
        case TYPE.UPDATE_OTA_STATUS:
            state.updateOtaListRes=action.payload
            return { ...state, response: action.payload }
        case "clear_update_ota_list":
            state.updateOtaListRes={}
            return { ...state, response: action.payload }
        case "clear_edit_tax_value":
            state.editTaxValueRes = {}
            return {...state, response: action.payload}
        case "clear_cache_response":
            state.clearCacheRes={}
            return { ...state, response: action.payload }
        case "clear_edit_contact_number_and_msg":
            state.editContactNoAndMsgRes={}
            return { ...state, response: action.payload }
        default:
            return state;
    }
}

