import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomHeaderComponent from "../Header_footer/Header/customHeaderComponent";
import adminImages from "../../assets";
import { Loader, Image, Button } from "semantic-ui-react";
import HotelListingCustomDropDown from "../RegisterHotel_New/hotelListingCustomDropDown";
import CustomInputField from "./CustomInputField";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import SingleDateCustomCalendar from "../SingleDateCustomCalendar";
import MultipleDateCustomCalendar from "../MultipleDateCustomCalendar";
import {
  updatePromotion,
  getPromotionIdDetails,
  insertNewPromotion,
} from "../../redux/actions/promotion_actions";
import { SELECTED_HOTEL_ID, USER_DATA } from "../../constants";
import ResponsePopup from "../ResponsePopup";
import PromotionNameDD from "./PromotionNameDD";
export default function CreateEditPromotion(props) {
  const [btnLoading, setBtnLoading] = useState(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [isErrorPopup, setIsErrorPopup] = useState(false);
  const [isCreateScreen, setIsCreateScreen] = useState(
    props.match.params.id == "new"
  );
  const [promotionId, setPromotionId] = useState(null);
  const [promotionEncrptId, setPromotionEncrptId] = useState(null);
  const [isCreateButtonClicked, setCreateButtonClicked] = useState(false);
  const [selectedRoomRatePlan, setSelectedRoomRatePlan] = useState();
  const [openStayStartDate, setOpenStayStartDate] = useState(null);
  const [openBlackoutDate, setOpenBlackoutDate] = useState(null);
  const [openStayEndDate, setOpenStayEndDate] = useState(false);
  const [openBookingStartDate, setOpenBookingStartDate] = useState(false);
  const [openBookingEndDate, setOpenBookingEndDate] = useState(false);
  const [showCalendar, setShowCalender] = useState(false);
  const [isEditEnable, setIsEditEnable] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [promotionType, setPromotionType] = useState(null);
  const [promotionTypeDD, setPromotionTypeDD] = useState(false);
  let promotionTypeDDRef = useRef();
  const [offerType, setOfferType] = useState(null);
  const [offerTypeDD, setOfferTypeDD] = useState(false);
  let offerTypeDDRef = useRef();

  const [discountForAll, setDiscountForAll] = useState(null);
  const [discountForLoggedIn, setDiscountForLoggedIn] = useState("0");

  const [noOfBookedNight, setNoOfBookedNight] = useState(null);
  const [noOfFreeNight, setNoOfFreeNight] = useState(null);

  const [isSetMaxLengthStay, setIsSetMaxLengthStay] = useState(null);
  const [isSetMaxLengthStayDD, setIsSetMaxLengthStayDD] = useState(false);
  let isSetMaxLengthStayDDRef = useRef();
  const [applicableFreeNight, setApplicableFreeNight] = useState(null);
  const [applicableFreeNightDD, setApplicableFreeNightDD] = useState(false);
  let applicableFreeNightDDRef = useRef();
  const [isSetMinLengthStay, setIsSetMinLengthStay] = useState(null);
  const [isSetMinLengthStayDD, setIsSetMinLengthStayDD] = useState(false);
  let isSetMinLengthStayDDRef = useRef();
  const [maxStayDuration, setMaxStayDuration] = useState(null);
  const [maxStayDurationDD, setMaxStayDurationDD] = useState(false);
  let maxStayDurationDDRef = useRef();
  const [minStayDuration, setMinStayDuration] = useState(null);
  const [minStayDurationDD, setMinStayDurationDD] = useState(false);
  let minStayDurationDDRef = useRef();
  const [earlyBirdRule, setEarlyBirdRule] = useState(null);
  const [earlyBirdRuleDD, setEarlyBirdRuleDD] = useState(false);
  let earlyBirdRuleDDref = useRef();
  const [earlyBirdBookingWindow, setEarlyBirdBookingWindow] = useState(null);
  const [earlyBirdBookingWindowDD, setEarlyBirdBookingWindowDD] =
    useState(false);
  let earlyBirdBookingWindowDDRef = useRef();

  const [lastMinDealRule, setlastMinDealRule] = useState(null);
  const [lastMinDealRuleDD, setlastMinDealRuleDD] = useState(false);
  let lastMinDealRuleDDRef = useRef();

  const [lastMinDealBookingWindow, setlastMinDealBookingWindow] =
    useState(null);

  const [lastMinDealBookingWindowDD, setlastMinDealBookingWindowDD] =
    useState(false);
  let lastMinDealBookingWindowDDRef = useRef();

  const [isDayBasedApplicability, setIsDayBasedApplicabilty] = useState(null);
  const [isDayBasedApplicabilityDD, setIsDayBasedApplicabiltyDD] =
    useState(false);
  let isDayBasedApplicabilityDDRef = useRef();

  const [applicableDays, setApplicableDays] = useState(null);
  const [apiDataApplicableDays, setApiDataApplicableDays] = useState(null);

  const [applicableFor, setApplicableFor] = useState(null);
  const [applicableForDD, setApplicableForDD] = useState(false);
  let applicableForDDRef = useRef();

  const [stayStartDate, setStayStartDate] = useState(null);
  const [stayEndDate, setStayEndDate] = useState(null);
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [isBlackoutDates, setIsBlackoutDates] = useState(null);
  const [isBlackoutDatesDD, setIsBlackoutDatesDD] = useState(false);
  let isBlackoutDatesDDRef = useRef();

  const [ratePlanApplicability, setRatePlanApplicability] = useState(null);
  const [ratePlanApplicabilityDD, setRatePlanApplicabilityDD] = useState(false);
  let ratePlanApplicabilityDDRef = useRef();

  const [selectedBlackOutDays, setSelectedBlackoutDays] = useState([]);
  const [refundability, setRefundability] = useState(null);
  const [refundabilityDD, setRefundabilityDD] = useState(false);
  let refundabilityDDRef = useRef();
  const [expiryDate, setExpiryDate] = useState(null);
  const [openExpiryDate, setOpenExpiryDate] = useState(null);

  const [promotionName, setPromotionName] = useState(null);
  const [stayEndVisible, setStayEndDateVisible] = useState(false);
  const [bookingEndDateVisible, setBookingEndDateVisible] = useState(false);
  const [dropdownNameOptions, setDropdownNameOptions] = useState([
    "Last Minute Deal",
    "Early Bird Deal",
    "Special Offer",
    "Festive Offer",
    "Limited Time Deal",
  ]);
  const getPromotionDetailsRes = useSelector(
    (state) => state.PromotionResponse.getPromotionIdDetails
  );
  const insertPromotionRes = useSelector(
    (state) => state.PromotionResponse.insertPromotionRes
  );
  const updatePromotionRes = useSelector(
    (state) => state.PromotionResponse.updatePromotionRes
  );
  const [ratePlanIdArr, setRatePlanIdArr] = useState([]);
  const [weekDays, setWeekDays] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setIsCreateScreen(props.match.params.id == "new");
    getDetails();
  }, []);

  useEffect(() => {
    if (applicableDays) {
      const arr = [];
      applicableDays.map((key) => {
        arr.push(weekDays[key]);
      });
      setApiDataApplicableDays(arr);
    }
  }, [applicableDays]);

  useEffect(() => {
    if (updatePromotionRes.status) {
      setBtnLoading(false);
      setIsVisiblePopup(true);
      setPopupMsg(updatePromotionRes.msg);
      if (updatePromotionRes.status == "SUCCESS") {
        setIsErrorPopup(false);
      } else {
        setIsErrorPopup(true);
      }
      getDetails();

      dispatch({ type: "clear_update_promotion_res" });
      closeResponsePopup();
    }
  }, [updatePromotionRes]);

  useEffect(() => {
    if (insertPromotionRes.status) {
      setBtnLoading(false);
      setIsVisiblePopup(true);
      setPopupMsg(insertPromotionRes.msg);
      if (insertPromotionRes.status == "SUCCESS") {
        setIsErrorPopup(false);
        dispatch({ type: "clear_get_promotion_id_details" });
        props.history.push("/promotions");
      } else {
        setIsErrorPopup(true);
      }
      closeResponsePopup();
      dispatch({ type: "clear_insert_promotion_res" });
    }
  }, [insertPromotionRes]);

  useEffect(() => {
    if (getPromotionDetailsRes.status) {
      setDataLoading(false);
      setCreateButtonClicked(false);
      if (getPromotionDetailsRes.status == "SUCCESS") {
        setWeekDays(getPromotionDetailsRes.data.days);
        setIsErrorOccurred(false);
        setValues();
      } else {
        setIsErrorOccurred(true);
      }
    }
  }, [getPromotionDetailsRes]);

  useEffect(() => {
    let handler = (e) => {
      if (promotionTypeDD) {
        if (!promotionTypeDDRef.current.contains(e.target)) {
          setPromotionTypeDD(false);
        }
      } else if (offerTypeDD) {
        if (!offerTypeDDRef.current.contains(e.target)) {
          setOfferTypeDD(false);
        }
      } else if (isSetMaxLengthStayDD) {
        if (!isSetMaxLengthStayDDRef.current.contains(e.target)) {
          setIsSetMaxLengthStayDD(false);
        }
      } else if (applicableFreeNightDD) {
        if (!applicableFreeNightDDRef.current.contains(e.target)) {
          setApplicableFreeNightDD(false);
        }
      } else if (maxStayDurationDD) {
        if (!maxStayDurationDDRef.current.contains(e.target)) {
          setMaxStayDurationDD(false);
        }
      } else if (isSetMinLengthStayDD) {
        if (!isSetMinLengthStayDDRef.current.contains(e.target)) {
          setIsSetMinLengthStayDD(false);
        }
      } else if (minStayDurationDD) {
        if (!minStayDurationDDRef.current.contains(e.target)) {
          setMinStayDurationDD(false);
        }
      } else if (isSetMinLengthStayDD) {
        if (!isSetMinLengthStayDDRef.current.contains(e.target)) {
          setIsSetMinLengthStayDD(false);
        }
      } else if (minStayDurationDD) {
        if (!minStayDurationDDRef.current.contains(e.target)) {
          setMinStayDurationDD(false);
        }
      } else if (earlyBirdRuleDD) {
        if (!earlyBirdRuleDDref.current.contains(e.target)) {
          setEarlyBirdRuleDD(false);
        }
      } else if (earlyBirdBookingWindowDD) {
        if (!earlyBirdBookingWindowDDRef.current.contains(e.target)) {
          setEarlyBirdBookingWindowDD(false);
        }
      } else if (lastMinDealRuleDD) {
        if (!lastMinDealRuleDDRef.current.contains(e.target)) {
          setlastMinDealRuleDD(false);
        }
      } else if (lastMinDealBookingWindowDD) {
        if (!lastMinDealBookingWindowDDRef.current.contains(e.target)) {
          setlastMinDealBookingWindowDD(false);
        }
      } else if (isDayBasedApplicabilityDD) {
        if (!isDayBasedApplicabilityDDRef.current.contains(e.target)) {
          setIsDayBasedApplicabiltyDD(false);
        }
      } else if (applicableForDD) {
        if (!applicableForDDRef.current.contains(e.target)) {
          setApplicableForDD(false);
        }
      } else if (isBlackoutDatesDD) {
        if (!isBlackoutDatesDDRef.current.contains(e.target)) {
          setIsBlackoutDatesDD(false);
        }
      } else if (refundabilityDD) {
        if (!refundabilityDDRef.current.contains(e.target)) {
          setRefundabilityDD(false);
        }
      } else if (ratePlanApplicabilityDD) {
        if (!ratePlanApplicabilityDDRef.current.contains(e.target)) {
          setRatePlanApplicabilityDD(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (
      notNullOrUndefined(getPromotionDetailsRes.data) &&
      notNullOrUndefined(selectedRoomRatePlan)
    ) {
      setRatePlanIdArr(getSelectedRatePlanIds());
    }
  }, [selectedRoomRatePlan]);

  useEffect(() => {
    if (notNullOrUndefined(bookingEndDateVisible) && !bookingEndDateVisible) {
      setBookingEndDate(null);
    }
    if (notNullOrUndefined(stayEndVisible) && !stayEndVisible) {
      setStayEndDate(null);
    }
  }, [bookingEndDateVisible, stayEndVisible]);

  useEffect(() => {
    setDropdownNameOptions([
      !notNullOrUndefined(lastMinDealRule) || lastMinDealRule.value == 1
        ? "Last Minute Deal"
        : null,
      !notNullOrUndefined(earlyBirdRule) || earlyBirdRule.value == 1
        ? "Early Bird Deal"
        : null,
      "Special Offer",
      "Festive Offer",
      "Limited Time Deal",
    ]);
  }, [lastMinDealRule, earlyBirdRule]);

  //For setting end date as null if start date is less than end date
  useEffect(() => {
    setStayEndDate(null);
  }, [stayStartDate]);

  useEffect(() => {
    setBookingEndDate(null);
  }, [bookingStartDate]);

  const promotionTypeOptions = [
    {
      key: "Discount",
      text: "Discount",
      value: "Discount",
    },
    {
      key: "Free Nights",
      text: "Free Nights",
      value: "Free Nights",
    },
  ];

  const applicableFreeNightOptions = [
    {
      key: "Last night",
      text: "Last night",
      value: "Last night",
    },
    // {
    //   key: "Any night",
    //   text: "Any night",
    //   value: "Any night",
    // },
    {
      key: "Cheapest night",
      text: "Cheapest night",
      value: "Cheapest night",
    },
  ];

  const offerTypeOptions = [
    { key: "Amount", text: "Amount", value: "Fixed" },
    { key: "Percentage", text: "Percentage", value: "Percentage" },
  ];

  const yesNoOptions = [
    { key: "Yes", text: "Yes", value: 1 },
    { key: "No", text: "No", value: 0 },
  ];

  const daysOption = [];

  for (let i = 1; i <= 30; i++) {
    const dayNumber = i < 10 ? `0${i}` : `${i}`;
    const dayObject = {
      key: `${dayNumber} Days`,
      text: `${dayNumber} Days`,
      value: dayNumber,
    };
    daysOption.push(dayObject);
  }

  const applicableForOptions = [
    { text: "Stay Date", key: "Stay Date", value: 0 },
    { text: "Booking Date", key: "Booking Date", value: 1 },
    {
      text: "Stay Date & Booking Date",
      key: "Stay Date & Booking Date",
      value: 2,
    },
  ];

  const applicabilityOptions = [
    {
      text: "Selected Rate Plans",
      value: 0,
      key: "Selected Rate Plans",
    },
    { text: "All Rate Plans", value: 1, key: "All Rate Plans" },
  ];

  const isNotEqual = (apiValue, inputValue) => {
    return !notNullOrUndefined(inputValue) || apiValue != inputValue;
  };

  const getDetails = () => {
    setDataLoading(true);
    const data = {
      login_user_id: JSON.parse(localStorage.getItem(USER_DATA)).login_user_id,
      hotel_id:
        props.match.params.id !== "new"
          ? undefined
          : localStorage.getItem(SELECTED_HOTEL_ID),
      promotion_id:
        props.match.params.id !== "new" ? props.match.params.id : undefined,
    };
    dispatch(getPromotionIdDetails(data)).catch((err) => {
      setIsErrorOccurred(true);
      setDataLoading(false);
      closeResponsePopup();
    });
  };

  const setValues = () => {
    if (props.match.params.id !== "new") {
      setIsEditEnable(true);

      //setting blackout dates info
      setIsBlackoutDates(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (notNullOrUndefined(getPromotionDetailsRes.data.blackout_dates) &&
            getPromotionDetailsRes.data.blackout_dates.length != 0
              ? 1
              : 0)
        )[0]
      );
      setSelectedBlackoutDays(getPromotionDetailsRes.data.blackout_dates);

      //setting promotion ids
      setPromotionEncrptId(getPromotionDetailsRes.data.ob_pp_encrypted_id);
      setPromotionId(getPromotionDetailsRes.data.ob_pp_id);

      //setting promotion name
      setPromotionName(getPromotionDetailsRes.data.ob_pp_name);

      //setting promotion expiry date
      setExpiryDate(getPromotionDetailsRes.data.ob_pp_end_date);

      //setting day based applicabity data

      setIsDayBasedApplicabilty(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (getPromotionDetailsRes.data.day_based_applicability == null ||
            getPromotionDetailsRes.data.day_based_applicability.length == 0
              ? 0
              : 1)
        )[0]
      );

      if (
        getPromotionDetailsRes.data.day_based_applicability == null ||
        getPromotionDetailsRes.data.day_based_applicability.length == 0
      ) {
        setApplicableDays(null);
      } else {
        setApplicableDays(getPromotionDetailsRes.data.day_based_applicability);
      }

      //setting refundability data
      setRefundability(
        refundabiltyOptions.filter(
          (item) =>
            item.value == getPromotionDetailsRes.data.ob_pp_is_non_refundable
        )[0]
      );

      //setting promotion type
      setPromotionType(
        promotionTypeOptions.filter(
          (item) =>
            item.value == getPromotionDetailsRes.data.ob_pp_promotion_type
        )[0]
      );

      //on the basis of promotion setting other values(offer type ,discount for all , discount for logged in etc.)
      if (getPromotionDetailsRes.data.ob_pp_promotion_type == "Discount") {
        setOfferType(
          offerTypeOptions.filter(
            (item) => item.value == getPromotionDetailsRes.data.ob_pp_offer_type
          )[0]
        );
        setApplicableFreeNight(null);
        setNoOfBookedNight(null);
        setNoOfFreeNight(null);
        setDiscountForAll(
          getPromotionDetailsRes.data.ob_pp_offer_value_all_user
        );
        setDiscountForLoggedIn(
          getPromotionDetailsRes.data.ob_pp_offer_value_loggedin_user -
            getPromotionDetailsRes.data.ob_pp_offer_value_all_user
        );
      } else {
        setOfferType(null);
        setDiscountForAll(null);
        setDiscountForLoggedIn("0");
        setNoOfBookedNight(
          getPromotionDetailsRes.data.ob_pp_book_rooms_for_free_nights
        );
        setNoOfFreeNight(getPromotionDetailsRes.data.ob_pp_free_nights);
        setApplicableFreeNight(
          applicableFreeNightOptions.filter(
            (item) =>
              item.value ==
              getPromotionDetailsRes.data.ob_pp_applicable_free_nights
          )[0]
        );
      }

      // setting max stay duration data
      setIsSetMaxLengthStay(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (getPromotionDetailsRes.data.ob_pp_max_stay_days != null ? 1 : 0)
        )[0]
      );

      if (notNullOrUndefined(getPromotionDetailsRes.data.ob_pp_max_stay_days)) {
        setMaxStayDuration(
          daysOption.filter(
            (item) =>
              parseInt(item.value) ==
              getPromotionDetailsRes.data.ob_pp_max_stay_days
          )[0]
        );
      } else {
        setMaxStayDuration(null);
      }

      // setting min stay duration data
      setIsSetMinLengthStay(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (getPromotionDetailsRes.data.ob_pp_min_stay_days != null ? 1 : 0)
        )[0]
      );

      if (notNullOrUndefined(getPromotionDetailsRes.data.ob_pp_min_stay_days)) {
        setMinStayDuration(
          daysOption.filter(
            (item) =>
              parseInt(item.value) ==
              getPromotionDetailsRes.data.ob_pp_min_stay_days
          )[0]
        );
      } else {
        setMinStayDuration(null);
      }

      //settig early bird rule data
      setEarlyBirdRule(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window !=
            null
              ? 1
              : 0)
        )[0]
      );
      if (
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window
        )
      ) {
        setEarlyBirdBookingWindow(
          daysOption.filter(
            (item) =>
              parseInt(item.value) ==
              getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window
          )[0]
        );
      } else {
        setEarlyBirdBookingWindow(null);
      }

      //setting last min deal rule
      setlastMinDealRule(
        yesNoOptions.filter(
          (item) =>
            item.value ==
            (getPromotionDetailsRes.data
              .ob_pp_last_minute_deal_rule_booking_window != null
              ? 1
              : 0)
        )[0]
      );

      if (
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_last_minute_deal_rule_booking_window
        )
      ) {
        setlastMinDealBookingWindow(
          daysOption.filter(
            (item) =>
              parseInt(item.value) ==
              getPromotionDetailsRes.data
                .ob_pp_last_minute_deal_rule_booking_window
          )[0]
        );
      } else {
        setlastMinDealBookingWindow(null);
      }

      if (
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        ) &&
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        )
      ) {
        setApplicableFor({
          text: "Stay Date & Booking Date",
          key: "Stay Date & Booking Date",
          value: 2,
        });
        setBookingStartDate(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        );
        if (
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_booking_applicability_end
          )
        ) {
          setBookingEndDate(
            getPromotionDetailsRes.data.ob_pp_booking_applicability_end
          );
          setBookingEndDateVisible(true);
        } else {
          setBookingEndDateVisible(false);
        }
        setStayStartDate(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        );
        if (
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_stay_applicability_end
          )
        ) {
          setStayEndDate(
            getPromotionDetailsRes.data.ob_pp_stay_applicability_end
          );
          setStayEndDateVisible(true);
        } else {
          setStayEndDateVisible(false);
        }
      } else if (
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        )
      ) {
        setApplicableFor({
          text: "Stay Date",
          key: "Stay Date",
          value: 0,
        });
        setStayStartDate(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        );
        setBookingStartDate(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        );
        setBookingEndDate(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_end
        );

        if (
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_stay_applicability_end
          )
        ) {
          setStayEndDate(
            getPromotionDetailsRes.data.ob_pp_stay_applicability_end
          );
          setStayEndDateVisible(true);
        } else {
          setStayEndDateVisible(false);
        }
      } else if (
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        )
      ) {
        setApplicableFor({
          text: "Booking Date",
          key: "Booking Date",
          value: 1,
        });
        setStayStartDate(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        );
        setStayEndDate(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_end
        );

        setBookingStartDate(
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        );
        if (
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_booking_applicability_end
          )
        ) {
          setBookingEndDate(
            getPromotionDetailsRes.data.ob_pp_booking_applicability_end
          );
          setBookingEndDateVisible(true);
        } else {
          setBookingEndDateVisible(false);
        }
      }

      //setting rate plan appicability data

      if (notNullOrUndefined(getPromotionDetailsRes.data.applicability)) {
        setRatePlanApplicability(
          applicabilityOptions.filter(
            (item) =>
              item.value ==
              getPromotionDetailsRes.data.applicability
                .is_all_rate_plan_available
          )[0]
        );
        setSelectedRoomRatePlan(
          getPromotionDetailsRes.data.applicability.room_list
        );
      }
      // setIsBlackoutDates()
    } else {
      setSelectedRoomRatePlan(getPromotionDetailsRes.data.room_list);
    }
  };

  const scrollTo = (value) => {
    const element = document.getElementById(value);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleInputChange = (e, inputFor) => {
    if (e.target.value.length === 0 || /^[0-9]+$/.test(e.target.value)) {
      if (inputFor == "Discount for all users") {
        if (offerType.value == "Percentage") {
          if (
            (parseInt(e.target.value) <= 100 &&
              !(
                parseInt(discountForAll) == 0 && parseInt(e.target.value) == 0
              )) ||
            e.target.value == ""
          ) {
            setDiscountForAll(e.target.value);
            if (
              parseInt(e.target.value) + parseInt(discountForLoggedIn) >
              100
            ) {
              setDiscountForLoggedIn("0");
            }
          }
        } else {
          if (
            (parseInt(e.target.value) <= 99999 &&
              !(
                parseInt(discountForAll) == 0 && parseInt(e.target.value) == 0
              )) ||
            e.target.value == ""
          )
            setDiscountForAll(e.target.value);
        }
      }

      if (inputFor == "Discount for logged in user") {
        if (offerType.value == "Percentage") {
          if (
            (parseInt(e.target.value) + parseInt(discountForAll) <= 100 &&
              !(
                parseInt(discountForLoggedIn) == 0 &&
                parseInt(e.target.value) == 0
              )) ||
            e.target.value == ""
          ) {
            setDiscountForLoggedIn(e.target.value);
          }
        } else {
          if (
            (parseInt(e.target.value) <= 99999 &&
              !(
                parseInt(discountForLoggedIn) == 0 &&
                parseInt(e.target.value) == 0
              )) ||
            e.target.value == ""
          ) {
            setDiscountForLoggedIn(e.target.value);
          }
        }
      }

      if (inputFor == "Discount for logged in user booked") {
        if (
          !(parseInt(noOfBookedNight) == 0 && parseInt(e.target.value) == 0)
        ) {
          setNoOfBookedNight(e.target.value);
        }
      }
      if (inputFor == "Discount for logged in user free") {
        if (!(parseInt(noOfFreeNight) == 0 && parseInt(e.target.value) == 0)) {
          setNoOfFreeNight(e.target.value);
        }
      }
    }
    if (inputFor == "Promotion Name") {
      if (e.target.value.length < 21) {
        setPromotionName(formatPromotionName(e.target.value));
        setErrorMsg("");
      } else {
        setErrorMsg("**Max Character Limit is 20");
        setTimeout(() => {
          setErrorMsg("");
        }, 2000);
      }
    }
  };

  const refundabiltyOptions = [
    { key: "Refundable", text: "Refundable", value: 0 },
    { key: "Non-Refundable", text: "Non-Refundable", value: 1 },
  ];

  const openBlackOutCalendar = () => {
    setOpenBlackoutDate(true);
    setShowCalender(true);
  };

  const onHandleDropDown = (type) => {
    if (type == "Promotion Type") {
      setPromotionTypeDD(!promotionTypeDD);
    } else if (type == "Offer Type") {
      setOfferTypeDD(!offerTypeDD);
    } else if (type == "Set Max Length of Stay") {
      setIsSetMaxLengthStayDD(!isSetMaxLengthStayDD);
    } else if (type == "Set Applicable Free Nights") {
      setApplicableFreeNightDD(!applicableFreeNightDD);
    } else if (type == "Max Stay Duration for Discount") {
      setMaxStayDurationDD(!maxStayDurationDD);
    } else if (type == "Set Min Length of Stay") {
      setIsSetMinLengthStayDD(!isSetMinLengthStayDD);
    } else if (type == "Min Stay Duration for Discount") {
      setMinStayDurationDD(!minStayDurationDD);
    } else if (type == "Set Early Bird Rule") {
      setEarlyBirdRuleDD(!earlyBirdRuleDD);
    } else if (type == "Early Bird Booking Window") {
      setEarlyBirdBookingWindowDD(!earlyBirdBookingWindowDD);
    } else if (type == "Set Last Minute Deal Rule") {
      setlastMinDealRuleDD(!lastMinDealRuleDD);
    } else if (type == "Last Minute Booking Window") {
      setlastMinDealBookingWindowDD(!lastMinDealBookingWindowDD);
    } else if (type == "Set Day Based Applicability") {
      setIsDayBasedApplicabiltyDD(!isDayBasedApplicabilityDD);
    } else if (type == "Applicable For") {
      setApplicableForDD(!applicableForDD);
    } else if (type == "Set Blackout Stay Dates") {
      setIsBlackoutDatesDD(!isBlackoutDatesDD);
    } else if (type == "Set Refundability") {
      setRefundabilityDD(!refundabilityDD);
    } else if (type == "Rate Plan Applicability") {
      setRatePlanApplicabilityDD(!ratePlanApplicabilityDD);
    }
  };

  const onSelectDropDownOption = (type, option) => {
    if (type == "Promotion Type") {
      setPromotionType(option);
      setPromotionTypeDD(false);
    } else if (type == "Offer Type") {
      setOfferType(option);
      setOfferTypeDD(false);
      setDiscountForAll(null);
      setDiscountForLoggedIn("0");
    } else if (type == "Set Max Length of Stay") {
      setIsSetMaxLengthStay(option);
      setIsSetMaxLengthStayDD(false);
    } else if (type == "Set Applicable Free Nights") {
      setApplicableFreeNight(option);
      setApplicableFreeNightDD(false);
    } else if (type == "Max Stay Duration for Discount") {
      setMaxStayDuration(option);
      setMaxStayDurationDD(false);
    } else if (type == "Set Min Length of Stay") {
      setIsSetMinLengthStay(option);
      setIsSetMinLengthStayDD(false);
    } else if (type == "Min Stay Duration for Discount") {
      setMinStayDuration(option);
      setMinStayDurationDD(false);
    } else if (type == "Set Early Bird Rule") {
      setEarlyBirdRule(option);
      setEarlyBirdRuleDD(false);
    } else if (type == "Early Bird Booking Window") {
      setEarlyBirdBookingWindow(option);
      setEarlyBirdBookingWindowDD(false);
    } else if (type == "Set Last Minute Deal Rule") {
      setlastMinDealRule(option);
      setlastMinDealRuleDD(false);
    } else if (type == "Last Minute Booking Window") {
      setlastMinDealBookingWindow(option);
      setlastMinDealBookingWindowDD(false);
    } else if (type == "Set Day Based Applicability") {
      setIsDayBasedApplicabilty(option);
      setIsDayBasedApplicabiltyDD(false);
    } else if (type == "Applicable For") {
      setApplicableFor(option);
      setApplicableForDD(false);
    } else if (type == "Set Blackout Stay Dates") {
      setIsBlackoutDates(option);
      setIsBlackoutDatesDD(false);
    } else if (type == "Set Refundability") {
      setRefundability(option);
      setRefundabilityDD(false);
    } else if (type == "Rate Plan Applicability") {
      setRatePlanApplicability(option);
      setRatePlanApplicabilityDD(false);
    }
  };

  const formatPromotionName = (value) => {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const notNullOrUndefined = (value) => {
    return value != null && value != undefined;
  };

  const handleCreateUpdateBtn = () => {
    if (!notNullOrUndefined(stayEndDate)) {
      setStayEndDateVisible(false);
    }
    if (!notNullOrUndefined(bookingEndDate)) {
      setBookingEndDateVisible(false);
    }
    setCreateButtonClicked(true);
    if (
      !notNullOrUndefined(promotionType) ||
      (promotionType.value == "Discount" &&
        (!notNullOrUndefined(offerType) ||
          !notNullOrUndefined(discountForAll) ||
          !notNullOrUndefined(discountForLoggedIn) ||
          discountForAll == "" ||
          String(discountForLoggedIn) == "")) ||
      (promotionType.value == "Free Nights" &&
        (!notNullOrUndefined(noOfBookedNight) ||
          !notNullOrUndefined(noOfFreeNight) ||
          noOfBookedNight == "" ||
          noOfFreeNight == "" ||
          noOfBookedNight < noOfFreeNight ||
          !notNullOrUndefined(applicableFreeNight)))
    ) {
      scrollTo("1");
    } else if (
      !notNullOrUndefined(isSetMaxLengthStay) ||
      (isSetMaxLengthStay.value == 1 && !notNullOrUndefined(maxStayDuration)) ||
      !notNullOrUndefined(isSetMinLengthStay) ||
      (isSetMinLengthStay.value == 1 && !notNullOrUndefined(minStayDuration)) ||
      (isSetMaxLengthStay.value == 1 &&
        isSetMinLengthStay.value == 1 &&
        notNullOrUndefined(maxStayDuration) &&
        notNullOrUndefined(minStayDuration) &&
        parseInt(maxStayDuration.value) <= parseInt(minStayDuration.value))
    ) {
      scrollTo("2");
    } else if (
      !notNullOrUndefined(earlyBirdRule) ||
      (earlyBirdRule.value == 1 &&
        !notNullOrUndefined(earlyBirdBookingWindow)) ||
      !notNullOrUndefined(lastMinDealRule) ||
      (lastMinDealRule.value == 1 &&
        !notNullOrUndefined(lastMinDealBookingWindow))
    ) {
      scrollTo("3");
    } else if (
      !notNullOrUndefined(isDayBasedApplicability) ||
      (isDayBasedApplicability.value == 1 &&
        (!notNullOrUndefined(applicableDays) || applicableDays.length == 0))
    ) {
      scrollTo("4");
    } else if (
      !notNullOrUndefined(applicableFor) ||
      (applicableFor.value == 2 &&
        (!notNullOrUndefined(stayStartDate) ||
          !notNullOrUndefined(bookingStartDate))) ||
      (applicableFor.value == 0 && !notNullOrUndefined(stayStartDate)) ||
      (applicableFor.value == 1 && !notNullOrUndefined(bookingStartDate))
    ) {
      scrollTo("5");
    } else if (
      !notNullOrUndefined(isBlackoutDates) ||
      (isBlackoutDates.value == 1 &&
        (!notNullOrUndefined(selectedBlackOutDays) ||
          selectedBlackOutDays.length == 0)) ||
      !notNullOrUndefined(ratePlanApplicability) ||
      (ratePlanApplicability.value == 0 && ratePlanIdArr.length == 0) ||
      !notNullOrUndefined(refundability)
    ) {
      scrollTo("6");
    } else if (!notNullOrUndefined(expiryDate)) {
      scrollTo("7");
    } else if (!notNullOrUndefined(promotionName) || promotionName == "") {
      scrollTo("8");
    } else {
      if (props.match.params.id == "new") {
        onCreatePromotion();
      } else {
        onUpdatePromotion();
      }
    }
  };

  const onCreatePromotion = () => {
    setBtnLoading(true);
    const data = {
      login_user_id: JSON.parse(localStorage.getItem(USER_DATA)).login_user_id,
      ob_pp_property_id: localStorage.getItem(SELECTED_HOTEL_ID),
      ob_pp_is_active: 0,

      // Step 1:  Offer Type
      ob_pp_promotion_type: promotionType.value,

      //if promotion type == Discount
      ob_pp_offer_type:
        promotionType.value == "Discount" ? offerType.value : null,
      ob_pp_offer_value_all_user:
        promotionType.value == "Discount" ? parseInt(discountForAll) : null,
      ob_pp_offer_value_loggedin_user:
        promotionType.value == "Discount"
          ? parseInt(discountForLoggedIn) + parseInt(discountForAll)
          : null,

      // if promotion type == Free night
      ob_pp_applicable_free_nights:
        promotionType.value == "Discount" ? null : applicableFreeNight.value,
      ob_pp_book_rooms_for_free_nights:
        promotionType.value == "Discount" ? null : parseInt(noOfBookedNight),
      ob_pp_free_nights:
        promotionType.value == "Discount" ? null : parseInt(noOfFreeNight),

      // Step 2:  Offer Restrictions
      max_length_of_stay: isSetMaxLengthStay.value,
      min_length_of_stay: isSetMinLengthStay.value,
      ob_pp_max_stay_days:
        isSetMaxLengthStay.value == 1 ? parseInt(maxStayDuration.value) : null,
      ob_pp_min_stay_days:
        isSetMinLengthStay.value == 1 ? parseInt(minStayDuration.value) : null,

      // Step 3:  Booking Window
      set_early_bird_rule: earlyBirdRule.value,
      ob_pp_early_bird_rule_booking_window:
        earlyBirdRule.value == 1
          ? parseInt(earlyBirdBookingWindow.value)
          : null,

      set_last_minute_deal_rule: lastMinDealRule.value,
      ob_pp_last_minute_deal_rule_booking_window:
        lastMinDealRule.value == 1
          ? parseInt(lastMinDealBookingWindow.value)
          : null,

      //Step 4:  Day Based Applicability
      set_day_based_applicability: parseInt(isDayBasedApplicability.value),
      days:
        parseInt(isDayBasedApplicability.value) == 1
          ? apiDataApplicableDays
          : null,

      // Step 5:  Promotion Dates
      set_date_based_applicability: applicableFor.value,
      ob_pp_booking_applicability_start:
        applicableFor.value != 0
          ? moment(bookingStartDate).format("YYYY-MM-DD")
          : null,
      ob_pp_booking_applicability_end:
        applicableFor.value != 0 && bookingEndDateVisible
          ? moment(bookingEndDate).format("YYYY-MM-DD")
          : null,
      ob_pp_stay_applicability_start:
        applicableFor.value != 1
          ? moment(stayStartDate).format("YYYY-MM-DD")
          : null,
      ob_pp_stay_applicability_end:
        applicableFor.value != 1 && stayEndVisible
          ? moment(stayEndDate).format("YYYY-MM-DD")
          : null,

      //Step 6:  Promotion Settings
      set_blackout_stay_dates: parseInt(isBlackoutDates.value),
      blackout_dates: selectedBlackOutDays,

      set_refundability: refundability.value == 0 ? 1 : 0,
      rate_plans: getSelectedRatePlanIds(),
      is_all_rate_plans_applicable: parseInt(ratePlanApplicability.value),

      // Step 7:  Promotion Expiry Date
      ob_pp_end_date: moment(expiryDate, "YYYY-MM-DD").format("YYYY-MM-DD"),

      // Step 8:  Confirmation
      ob_pp_name: promotionName,
    };

    dispatch(insertNewPromotion(data)).catch((err) => {
      setBtnLoading(false);
      setIsVisiblePopup(true);
      setPopupMsg("Something Went Wrong");
      setIsErrorPopup(true);
      closeResponsePopup();
      dispatch({ type: "clear_insert_promotion_res" });
    });
  };

  const onUpdatePromotion = () => {
    setBtnLoading(true);

    const data = {
      ob_pp_id: promotionEncrptId,
      login_user_id: JSON.parse(localStorage.getItem(USER_DATA)).login_user_id,
    };
    //1 offer type
    if (
      notNullOrUndefined(promotionType) &&
      isNotEqual(
        getPromotionDetailsRes.data.ob_pp_promotion_type,
        promotionType.value
      )
    ) {
      data.ob_pp_promotion_type = promotionType.value;
    }

    if (promotionType.value == "Discount") {
      if (
        (notNullOrUndefined(offerType) &&
          notNullOrUndefined(getPromotionDetailsRes.data) &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_offer_type,
            offerType.value
          )) ||
        (notNullOrUndefined(discountForLoggedIn) &&
          discountForLoggedIn != "" &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_offer_value_loggedin_user,
            parseInt(discountForLoggedIn) + parseInt(discountForAll)
          )) ||
        (notNullOrUndefined(discountForAll) &&
          discountForAll != "" &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_offer_value_all_user,
            discountForAll
          ))
      ) {
        data.ob_pp_promotion_type = promotionType.value;

        data.ob_pp_offer_type = offerType.value;
        data.ob_pp_offer_value_loggedin_user =
          parseInt(discountForLoggedIn) + parseInt(discountForAll);
        data.ob_pp_offer_value_all_user = parseInt(discountForAll);
      }
    } else {
      if (
        (notNullOrUndefined(noOfBookedNight) &&
          noOfBookedNight != "" &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_book_rooms_for_free_nights,
            noOfBookedNight
          )) ||
        (notNullOrUndefined(noOfFreeNight) &&
          noOfFreeNight != "" &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_free_nights,
            noOfFreeNight
          )) ||
        (notNullOrUndefined(applicableFreeNight) &&
          isNotEqual(
            getPromotionDetailsRes.data.ob_pp_applicable_free_nights,
            applicableFreeNight.value
          ))
      ) {
        data.ob_pp_promotion_type = promotionType.value;

        data.ob_pp_book_rooms_for_free_nights = parseInt(noOfBookedNight);
        data.ob_pp_free_nights = noOfFreeNight;
        data.ob_pp_applicable_free_nights = applicableFreeNight.value;
      }
    }

    //Step 2: Offer Restrictions
    if (
      isNotEqual(
        notNullOrUndefined(getPromotionDetailsRes.data.ob_pp_max_stay_days)
          ? 1
          : 0,
        isSetMaxLengthStay.value
      )
    ) {
      data.max_length_of_stay = isSetMaxLengthStay.value;
    }

    if (
      isNotEqual(
        notNullOrUndefined(getPromotionDetailsRes.data.ob_pp_min_stay_days)
          ? 1
          : 0,
        isSetMinLengthStay.value
      )
    ) {
      data.min_length_of_stay = isSetMinLengthStay.value;
    }

    if (
      notNullOrUndefined(isSetMaxLengthStay) &&
      isSetMaxLengthStay.value == 1 &&
      notNullOrUndefined(maxStayDuration) &&
      isNotEqual(
        getPromotionDetailsRes.data.ob_pp_max_stay_days,
        parseInt(maxStayDuration.value)
      ) &&
      (notNullOrUndefined(maxStayDuration) &&
      notNullOrUndefined(minStayDuration)
        ? parseInt(maxStayDuration.value) > parseInt(minStayDuration.value)
        : true)
    ) {
      data.max_length_of_stay = isSetMaxLengthStay.value;

      data.ob_pp_max_stay_days = maxStayDuration.value;
    }

    if (
      notNullOrUndefined(minStayDuration) &&
      isSetMinLengthStay.value == 1 &&
      isNotEqual(
        getPromotionDetailsRes.data.ob_pp_min_stay_days,
        parseInt(minStayDuration.value)
      ) &&
      (notNullOrUndefined(maxStayDuration) &&
      notNullOrUndefined(minStayDuration)
        ? parseInt(maxStayDuration.value) > parseInt(minStayDuration.value)
        : true)
    ) {
      data.min_length_of_stay = isSetMinLengthStay.value;
      data.ob_pp_min_stay_days = minStayDuration.value;
    }

    // Step 3: Booking Window

    if (
      isNotEqual(
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window
        )
          ? 1
          : 0,
        earlyBirdRule.value
      )
    ) {
      data.set_early_bird_rule = earlyBirdRule.value;
    }

    if (earlyBirdRule.value == 1) {
      if (
        notNullOrUndefined(earlyBirdBookingWindow) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window,
          parseInt(earlyBirdBookingWindow.value)
        )
      ) {
        data.set_early_bird_rule = earlyBirdRule.value;

        data.ob_pp_early_bird_rule_booking_window =
          earlyBirdBookingWindow.value;
      }
    } else {
      if (
        getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window != null
      ) {
        data.set_early_bird_rule = earlyBirdRule.value;

        data.ob_pp_early_bird_rule_booking_window = null;
      }
    }

    if (lastMinDealRule.value == 1) {
      if (
        notNullOrUndefined(lastMinDealBookingWindow) &&
        isNotEqual(
          getPromotionDetailsRes.data
            .ob_pp_last_minute_deal_rule_booking_window,
          parseInt(lastMinDealBookingWindow.value)
        )
      ) {
        data.set_last_minute_deal_rule = lastMinDealRule.value;

        data.ob_pp_last_minute_deal_rule_booking_window =
          lastMinDealBookingWindow.value;
      }
    } else {
      if (
        getPromotionDetailsRes.data
          .ob_pp_last_minute_deal_rule_booking_window != null
      ) {
        data.set_last_minute_deal_rule = lastMinDealRule.value;

        data.ob_pp_last_minute_deal_rule_booking_window = null;
      }
    }

    // Step 4: Day Based Applicability
    if (
      isNotEqual(
        notNullOrUndefined(
          getPromotionDetailsRes.data.day_based_applicability
        ) && getPromotionDetailsRes.data.day_based_applicability.length != 0
          ? 1
          : 0,
        isDayBasedApplicability.value
      )
    ) {
      data.set_day_based_applicability = isDayBasedApplicability.value;
    }
    if (isDayBasedApplicability.value == 1) {
      if (
        notNullOrUndefined(applicableDays) &&
        !areValuesEqual(
          getPromotionDetailsRes.data.day_based_applicability,
          applicableDays
        )
      ) {
        data.set_day_based_applicability = isDayBasedApplicability.value;

        data.days = apiDataApplicableDays;
      }
    }

    // Step 5: Promotion Dates
    if (
      isNotEqual(
        notNullOrUndefined(
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        ) &&
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_booking_applicability_start
          )
          ? 2
          : notNullOrUndefined(
              getPromotionDetailsRes.data.ob_pp_stay_applicability_start
            )
          ? 0
          : 1,
        applicableFor.value
      ) ||
      !isEqualDate(
        bookingStartDate,
        getPromotionDetailsRes.data.ob_pp_booking_applicability_start
      ) ||
      !isEqualDate(
        stayStartDate,
        getPromotionDetailsRes.data.ob_pp_stay_applicability_start
      )
    ) {
      data.set_date_based_applicability = applicableFor.value;
    }
    if (applicableFor.value != 1) {
      data.set_date_based_applicability = applicableFor.value;

      if (
        notNullOrUndefined(stayStartDate) ||
        !isEqualDate(
          stayEndDate,
          getPromotionDetailsRes.data.ob_pp_stay_applicability_end
        ) ||
        applicableFor.value == 2
      ) {
        data.ob_pp_stay_applicability_end = stayEndDate;
        data.ob_pp_stay_applicability_start = stayStartDate;
      }
    }

    if (applicableFor.value != 0) {
      data.set_date_based_applicability = applicableFor.value;

      if (
        notNullOrUndefined(bookingStartDate) ||
        !isEqualDate(
          bookingEndDate,
          getPromotionDetailsRes.data.ob_pp_booking_applicability_end
        ) ||
        applicableFor.value == 2
      ) {
        data.ob_pp_booking_applicability_end = bookingEndDate;
        data.ob_pp_booking_applicability_start = bookingStartDate;
      }
    }

    // Step 6: Promotion Settings

    if (
      isNotEqual(
        notNullOrUndefined(getPromotionDetailsRes.data.blackout_dates) &&
          getPromotionDetailsRes.data.blackout_dates.length != 0
          ? 1
          : 0,
        isBlackoutDates.value
      )
    ) {
      data.set_blackout_stay_dates = isBlackoutDates.value;
    }
    if (isBlackoutDates.value == 1) {
      if (
        notNullOrUndefined(selectedBlackOutDays) &&
        !areValuesEqual(
          getPromotionDetailsRes.data.blackout_dates,
          selectedBlackOutDays
        )
      ) {
        data.set_blackout_stay_dates = isBlackoutDates.value;

        data.blackout_dates = selectedBlackOutDays;
      }
    } else {
      // if (getPromotionDetailsRes.data.blackout_dates != null) {
      //   data.blackout_dates = null;
      // }
    }

    if (
      notNullOrUndefined(refundability) &&
      isNotEqual(
        getPromotionDetailsRes.data.ob_pp_is_non_refundable,
        refundability.value
      )
    ) {
      data.set_refundability = refundability.value == 0 ? 1 : 0;
    }

    if (
      notNullOrUndefined(ratePlanApplicability) &&
      notNullOrUndefined(getPromotionDetailsRes.data.applicability) &&
      isNotEqual(
        getPromotionDetailsRes.data.applicability.is_all_rate_plan_available,
        ratePlanApplicability.value
      )
    ) {
      data.ob_pp_property_id = localStorage.getItem(SELECTED_HOTEL_ID);
      data.is_all_rate_plans_applicable = ratePlanApplicability.value;
      data.rate_plans = ratePlanIdArr;
    }

    if (ratePlanApplicability.value == 0) {
      if (
        notNullOrUndefined(selectedRoomRatePlan) &&
        !areRatePlansEqual(
          getPromotionDetailsRes.data.applicability.room_list,
          selectedRoomRatePlan
        )
      ) {
        data.is_all_rate_plans_applicable = ratePlanApplicability.value;

        data.rate_plans = ratePlanIdArr;
      }
    }

    // Step 7: Promotion Expiry Date
    if (
      notNullOrUndefined(expiryDate) &&
      !isEqualDate(expiryDate, getPromotionDetailsRes.data.ob_pp_end_date)
    ) {
      data.ob_pp_end_date = expiryDate;
    }

    //Step 8: Confirmation
    if (
      notNullOrUndefined(promotionName) &&
      isNotEqual(getPromotionDetailsRes.data.ob_pp_name, promotionName)
    ) {
      data.ob_pp_name = promotionName;
    }
    dispatch(updatePromotion(data)).catch((err) => {
      setBtnLoading(false);
      setIsVisiblePopup(true);
      setIsErrorPopup(true);
      setPopupMsg("Some Error Occurred");
      dispatch({ type: "clear_update_promotion_res" });
    });
  };

  const closeResponsePopup = () => {
    setTimeout(() => {
      setIsVisiblePopup(false);
      setPopupMsg("");
      setIsErrorPopup(false);
    }, 1000);
  };

  const getSelectedRatePlanIds = () => {
    if (notNullOrUndefined(selectedRoomRatePlan)) {
      const tempArr = [];
      Object.keys(selectedRoomRatePlan).map(
        (key) =>
          selectedRoomRatePlan[key] != undefined &&
          selectedRoomRatePlan[key].map((item) => {
            if (item.is_selected == 1) {
              tempArr.push(item.rate_plan_id);
            }
          })
      );
      return tempArr;
    }
  };

  const arraysNotEqualIgnoringOrder = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return true;
    }

    const sortedArr1 = arr1
      .map((date) => moment(date, "YYYY-MM-DD"))
      .sort((a, b) => a - b);
    const sortedArr2 = arr2
      .map((date) => moment(date, "YYYY-MM-DD"))
      .sort((a, b) => a - b);

    for (let i = 0; i < sortedArr1.length; i++) {
      if (!sortedArr1[i].isSame(sortedArr2[i])) {
        return true;
      }
    }

    return false;
  };

  const disableUpdateBtn = () => {
    return !(
      (notNullOrUndefined(promotionType) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_promotion_type,
          promotionType.value
        )) ||
      (notNullOrUndefined(offerType) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_offer_type,
          offerType.value
        )) ||
      (notNullOrUndefined(discountForLoggedIn) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_offer_value_loggedin_user,
          parseInt(discountForLoggedIn) + parseInt(discountForAll)
        )) ||
      (notNullOrUndefined(discountForAll) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_offer_value_all_user,
          discountForAll
        )) ||
      (notNullOrUndefined(noOfBookedNight) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_book_rooms_for_free_nights,
          noOfBookedNight
        )) ||
      (notNullOrUndefined(noOfFreeNight) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_free_nights,
          noOfFreeNight
        )) ||
      (notNullOrUndefined(applicableFreeNight) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_applicable_free_nights,
          applicableFreeNight.value
        )) ||
      (notNullOrUndefined(isSetMaxLengthStay) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_max_stay_days != null ? 1 : 0,
          isSetMaxLengthStay.value
        )) ||
      (notNullOrUndefined(isSetMinLengthStay) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_min_stay_days != null ? 1 : 0,
          isSetMinLengthStay.value
        )) ||
      (notNullOrUndefined(isSetMaxLengthStay) &&
        notNullOrUndefined(maxStayDuration) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_max_stay_days,
          parseInt(maxStayDuration.value)
        )) ||
      (notNullOrUndefined(isSetMinLengthStay) &&
        notNullOrUndefined(minStayDuration) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_min_stay_days != null ? 1 : 0,
          isSetMinLengthStay.value
        )) ||
      (notNullOrUndefined(isSetMinLengthStay) &&
        notNullOrUndefined(minStayDuration) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_min_stay_days,
          parseInt(minStayDuration.value)
        ) &&
        (notNullOrUndefined(maxStayDuration) &&
        notNullOrUndefined(minStayDuration)
          ? parseInt(maxStayDuration.value) > parseInt(minStayDuration.value)
          : true)) ||
      (notNullOrUndefined(earlyBirdRule) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window !=
            null
            ? 1
            : 0,
          earlyBirdRule.value
        )) ||
      (notNullOrUndefined(earlyBirdBookingWindow) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_early_bird_rule_booking_window,
          parseInt(earlyBirdBookingWindow.value)
        )) ||
      (notNullOrUndefined(lastMinDealRule) &&
        isNotEqual(
          getPromotionDetailsRes.data
            .ob_pp_last_minute_deal_rule_booking_window != null
            ? 1
            : 0,
          lastMinDealRule.value
        )) ||
      (notNullOrUndefined(lastMinDealBookingWindow) &&
        isNotEqual(
          getPromotionDetailsRes.data
            .ob_pp_last_minute_deal_rule_booking_window,
          parseInt(lastMinDealBookingWindow.value)
        )) ||
      (notNullOrUndefined(isDayBasedApplicability) &&
        isNotEqual(
          getPromotionDetailsRes.data.day_based_applicability == null ||
            getPromotionDetailsRes.data.day_based_applicability.length == 0
            ? 0
            : 1,
          isDayBasedApplicability.value
        )) ||
      (notNullOrUndefined(isDayBasedApplicability) &&
        isDayBasedApplicability.value == 1 &&
        !areValuesEqual(
          getPromotionDetailsRes.data.day_based_applicability,
          applicableDays
        )) ||
      (notNullOrUndefined(isBlackoutDates) &&
        isBlackoutDates.value == 1 &&
        isNotEqual(
          getPromotionDetailsRes.data.blackout_dates == null ||
            getPromotionDetailsRes.data.blackout_dates.length == 0
            ? 0
            : 1,
          isBlackoutDates.value
        )) ||
      (notNullOrUndefined(selectedBlackOutDays) && isBlackoutDates.value == 1
        ? arraysNotEqualIgnoringOrder(
            getPromotionDetailsRes.data.blackout_dates,
            selectedBlackOutDays
          )
        : isNotEqual(
            getPromotionDetailsRes.data.blackout_dates == null ||
              getPromotionDetailsRes.data.blackout_dates.length == 0
              ? 0
              : 1,
            isBlackoutDates.value
          )) ||
      (notNullOrUndefined(applicableFor) &&
        isNotEqual(
          notNullOrUndefined(
            getPromotionDetailsRes.data.ob_pp_stay_applicability_start
          ) &&
            notNullOrUndefined(
              getPromotionDetailsRes.data.ob_pp_booking_applicability_start
            )
            ? 2
            : notNullOrUndefined(
                getPromotionDetailsRes.data.ob_pp_stay_applicability_start
              )
            ? 0
            : notNullOrUndefined(
                getPromotionDetailsRes.data.ob_pp_booking_applicability_start
              )
            ? 1
            : "",
          applicableFor.value
        )) ||
      (notNullOrUndefined(stayStartDate) &&
        !isEqualDate(
          stayStartDate,
          getPromotionDetailsRes.data.ob_pp_stay_applicability_start
        )) ||
      (notNullOrUndefined(stayEndDate) &&
        !isEqualDate(
          stayEndDate,
          getPromotionDetailsRes.data.ob_pp_stay_applicability_end
        )) ||
      (notNullOrUndefined(bookingStartDate) &&
        !isEqualDate(
          bookingStartDate,
          getPromotionDetailsRes.data.ob_pp_booking_applicability_start
        )) ||
      (notNullOrUndefined(bookingEndDate) &&
        !isEqualDate(
          bookingEndDate,
          getPromotionDetailsRes.data.ob_pp_booking_applicability_end
        )) ||
      (notNullOrUndefined(refundability) &&
        isNotEqual(
          getPromotionDetailsRes.data.ob_pp_is_non_refundable,
          refundability.value
        )) ||
      (notNullOrUndefined(ratePlanApplicability) &&
        notNullOrUndefined(getPromotionDetailsRes.data.applicability) &&
        isNotEqual(
          getPromotionDetailsRes.data.applicability.is_all_rate_plan_available,
          ratePlanApplicability.value
        )) ||
      (notNullOrUndefined(ratePlanApplicability) &&
        ratePlanApplicability.value == 0 &&
        !areRatePlansEqual(
          getPromotionDetailsRes.data.applicability.room_list,
          selectedRoomRatePlan
        )) ||
      (notNullOrUndefined(expiryDate) &&
        !isEqualDate(expiryDate, getPromotionDetailsRes.data.ob_pp_end_date)) ||
      (notNullOrUndefined(promotionName) &&
        isNotEqual(getPromotionDetailsRes.data.ob_pp_name, promotionName))
    );
  };

  const addDays = (day) => {
    if (notNullOrUndefined(applicableDays)) {
      if (applicableDays.length == 0) {
        setApplicableDays([day]);
      } else {
        if (applicableDays.includes(day)) {
          const updatedItems = applicableDays.filter(
            (item, index) => item !== day
          );
          setApplicableDays(updatedItems);
        } else {
          setApplicableDays([day, ...applicableDays]);
        }
      }
    } else {
      setApplicableDays([day]);
    }
  };

  const handleToggle = (type) => {
    if (type == "Stay Date") {
      setStayEndDateVisible(!stayEndVisible);
    } else {
      setBookingEndDateVisible(!bookingEndDateVisible);
    }
  };

  const hasAnyKeys = (obj) => {
    return Object.keys(obj).length > 0;
  };

  const removeDate = (date) => {
    const arr = selectedBlackOutDays.filter((item) => item != date);
    setSelectedBlackoutDays(arr);
  };

  const isEqualDate = (date1, date2) => {
    if (notNullOrUndefined(date1) && notNullOrUndefined(date2)) {
      return moment(date1).isSame(moment(date2));
    } else {
      return false;
    }
  };

  const isAllRatePlanSelected = (arr) => {
    return arr.every((item) => item.is_selected == 1);
  };

  const handleSelectAllRatePlanBox = (key, isAllSelected) => {
    const updated = { ...selectedRoomRatePlan };
    const list = selectedRoomRatePlan[key].map((item) => ({
      ...item,
      is_selected: isAllSelected ? 0 : 1,
    }));
    updated[key] = list;
    setSelectedRoomRatePlan(updated);
  };

  const handleSingleRatePlanSelection = (key, ratePlan, isSelected) => {
    const updatedState = { ...selectedRoomRatePlan };
    updatedState[key] = updatedState[key].map((item) => {
      if (item.rate_plan_name === ratePlan) {
        return { ...item, is_selected: isSelected ? 0 : 1 };
      }
      return item;
    });
    setSelectedRoomRatePlan(updatedState);
  };

  const areValuesEqual = (dates1, dates2) => {
    if (dates1.length !== dates2.length) {
      return false;
    }

    const sortedDates1 = dates1.slice().sort();
    const sortedDates2 = dates2.slice().sort();

    for (let i = 0; i < sortedDates1.length; i++) {
      if (sortedDates1[i] !== sortedDates2[i]) {
        return false;
      }
    }

    return true;
  };

  const areRatePlansEqual = (ratePlan1, ratePlan2) => {
    const keys1 = Object.keys(ratePlan1);
    const keys2 = Object.keys(ratePlan2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      const list1 = ratePlan1[key];
      const list2 = ratePlan2[key];

      if (list1.length !== list2.length) {
        return false;
      }

      for (let i = 0; i < list1.length; i++) {
        const item1 = list1[i];
        const item2 = list2[i];

        if (item1.is_selected !== item2.is_selected) {
          return false;
        }
      }
    }

    return true;
  };

  return (
    <div
      className="hl_cls"
      style={{
        backgroundColor: "#f1f1f1",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isVisiblePopup && (
        <ResponsePopup
          isVisiblePopup={isVisiblePopup}
          setIsVisiblePopup={setIsVisiblePopup}
          popupMsg={popupMsg}
          isErrorPopup={isErrorPopup}
        />
      )}
      <div className="hl_header_cls">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CustomHeaderComponent
            {...props}
            screenName={
              props.match.params.id == "new" ||
              !notNullOrUndefined(promotionName)
                ? "Custom Promotion"
                : promotionName
            }
          />
        </div>
        <div></div>
      </div>

      {dataLoading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Loader active inline="centered" />
          <p
            style={{
              marginTop: 10,
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#818181",
              fontSize: "1rem",
            }}
          >
            Loading Results...
          </p>
        </div>
      ) : isErrorOccurred || getPromotionDetailsRes.data == undefined ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src={adminImages.noResultsImg} className="no_results_img" />
          <p
            style={{
              marginTop: 10,
              textAlign: "center",
              fontFamily: "Montserrat",
              fontWeight: 400,
              color: "#818181",
              fontSize: "1rem",
            }}
          >
            Some Error Occured
          </p>
        </div>
      ) : (
        <div className="custom-promotion-outer-container">
          <div className="custom-promotion-inner-container">
            {/* Step 1: Offer Type */}
            <div className="cp_module_container" id={"1"}>
              <p className="custom-promotion-module-heading">
                Step 1: Offer Type
              </p>

              <HotelListingCustomDropDown
                label={"Promotion Type *"}
                placeholder="Select Promotion Type"
                dropdownStatus={promotionTypeDD}
                onHandleDropDown={onHandleDropDown}
                dropdownOptions={promotionTypeOptions}
                style={{ marginBottom: "0.25rem" }}
                onSelectDropDownOption={onSelectDropDownOption}
                dropdownValue={promotionType == null ? "" : promotionType}
                dropDownFor="Promotion Type"
                dropDownRef={promotionTypeDDRef}
              />
              {!isCreateScreen
                ? notNullOrUndefined(promotionType) &&
                  isNotEqual(
                    getPromotionDetailsRes.data.ob_pp_promotion_type,
                    promotionType.value
                  ) && (
                    <p className="p_text error p_text_sm p_text_regular">
                      **Save pending, Click update to Save
                    </p>
                  )
                : null}
              {isCreateButtonClicked && !notNullOrUndefined(promotionType) && (
                <p className="p_text error p_text_sm p_text_regular">
                  *Please Select Promotion Type
                </p>
              )}

              {notNullOrUndefined(promotionType) ? (
                promotionType.value == "Discount" ? (
                  <div className="cp_grey_box">
                    <HotelListingCustomDropDown
                      label={"Offer Type *"}
                      placeholder="Select Offer Type"
                      dropdownStatus={offerTypeDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={offerTypeOptions}
                      style={{ marginBottom: "0.25rem" }}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={offerType == null ? "" : offerType}
                      dropDownFor="Offer Type"
                      dropDownRef={offerTypeDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(offerType) &&
                        isNotEqual(
                          getPromotionDetailsRes.data.ob_pp_offer_type,
                          offerType.value
                        ) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(offerType) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Offer Type
                        </p>
                      )}
                    {offerType != null &&
                      offerType != undefined &&
                      offerType.value != "" && (
                        <div>
                          <div className="promo-input-field-container-grid">
                            <div>
                              <p className="hl_promotion_lable_cls">
                                Discount for all users *
                              </p>

                              <CustomInputField
                                type={
                                  offerType.value == "Percentage" ? "%" : "INR"
                                }
                                value={
                                  notNullOrUndefined(discountForAll)
                                    ? discountForAll
                                    : ""
                                }
                                style={{ width: "99%" }}
                                className={"all-user-discount-field"}
                                handleInputChange={handleInputChange}
                                placeholder={"Enter Discount Value"}
                                label={"Discount for all users *"}
                                inputFor={"Discount for all users"}
                              />
                            </div>
                            <div>
                              <div>
                                <p className="hl_promotion_lable_cls">
                                  Discount for logged in user *
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.25rem",
                                  }}
                                >
                                  <div
                                    className={"all-user-discount-box disabled"}
                                  >
                                    <p
                                      className="p_text p_text_md p_text_bold"
                                      style={{ color: "#818181" }}
                                    >
                                      {discountForAll}
                                    </p>
                                  </div>
                                  <p
                                    className="p_text p_text_sm p_text_bold"
                                    style={{ color: "#818181" }}
                                  >
                                    +
                                  </p>
                                  <CustomInputField
                                    type={
                                      offerType.value == "Percentage"
                                        ? "%"
                                        : "INR"
                                    }
                                    value={
                                      notNullOrUndefined(discountForLoggedIn)
                                        ? discountForLoggedIn
                                        : ""
                                    }
                                    className={"logged-in-user-discount-field"}
                                    handleInputChange={handleInputChange}
                                    placeholder={""}
                                    label={"Discount for logged in user *"}
                                    inputFor={"Discount for logged in user"}
                                    isDisable={
                                      discountForAll == null ||
                                      discountForAll == ""
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {!isCreateScreen &&
                            notNullOrUndefined(discountForLoggedIn) &&
                            discountForLoggedIn != "" &&
                            notNullOrUndefined(discountForAll) &&
                            discountForAll != "" &&
                            (isNotEqual(
                              getPromotionDetailsRes.data
                                .ob_pp_offer_value_loggedin_user,
                              parseInt(discountForLoggedIn) +
                                parseInt(discountForAll)
                            ) ||
                              isNotEqual(
                                getPromotionDetailsRes.data
                                  .ob_pp_offer_value_all_user,
                                discountForAll
                              )) && (
                              <p
                                className="p_text error p_text_sm p_text_regular"
                                style={{ marginTop: "0.25rem" }}
                              >
                                **Save pending, Click update to Save
                              </p>
                            )}
                          {isCreateButtonClicked &&
                            (!notNullOrUndefined(discountForLoggedIn) ||
                              String(discountForLoggedIn) == "" ||
                              !notNullOrUndefined(discountForAll) ||
                              discountForAll == "") && (
                              <p
                                className="p_text error p_text_sm p_text_regular"
                                style={{ marginTop: "0.25rem" }}
                              >
                                *Please Enter discount value for all user and
                                logged in user
                              </p>
                            )}
                        </div>
                      )}
                  </div>
                ) : (
                  <div
                    className="cp_grey_box"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                  >
                    <div>
                      <div>
                        <p className="hl_promotion_lable_cls">
                          Free night condition *
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <p
                            className="p_text p_text_sm p_text_bold"
                            style={{ color: "#818181" }}
                          >
                            Book
                          </p>
                          <CustomInputField
                            value={
                              notNullOrUndefined(noOfBookedNight)
                                ? noOfBookedNight
                                : ""
                            }
                            className={"input-box"}
                            handleInputChange={handleInputChange}
                            placeholder={""}
                            inputFor={"Discount for logged in user booked"}
                            isDisable={false}
                          />
                          <p
                            className="p_text p_text_sm p_text_bold"
                            style={{ color: "#818181" }}
                          >
                            nights and get
                          </p>
                          <CustomInputField
                            value={
                              notNullOrUndefined(noOfFreeNight)
                                ? noOfFreeNight
                                : ""
                            }
                            className={"input-box"}
                            handleInputChange={handleInputChange}
                            placeholder={""}
                            inputFor={"Discount for logged in user free"}
                            isDisable={false}
                          />
                          <p
                            className="p_text p_text_sm p_text_bold"
                            style={{ color: "#818181" }}
                          >
                            free
                          </p>
                        </div>
                      </div>
                      {notNullOrUndefined(noOfBookedNight) &&
                      notNullOrUndefined(noOfFreeNight) &&
                      noOfBookedNight != "" &&
                      noOfFreeNight != "" &&
                      parseInt(noOfBookedNight) < parseInt(noOfFreeNight) ? (
                        <p
                          className="p_text error p_text_sm p_text_regular"
                          style={{ marginTop: "0.25rem" }}
                        >
                          Free nights should be equal or less than booked
                          nights.
                        </p>
                      ) : (
                        !isCreateScreen &&
                        notNullOrUndefined(noOfBookedNight) &&
                        notNullOrUndefined(noOfFreeNight) &&
                        noOfBookedNight != "" &&
                        noOfFreeNight != "" &&
                        (isNotEqual(
                          getPromotionDetailsRes.data
                            .ob_pp_book_rooms_for_free_nights,
                          noOfBookedNight
                        ) ||
                          isNotEqual(
                            getPromotionDetailsRes.data.ob_pp_free_nights,
                            noOfFreeNight
                          )) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      )}
                      {isCreateButtonClicked &&
                        (!notNullOrUndefined(noOfBookedNight) ||
                          noOfBookedNight == "" ||
                          !notNullOrUndefined(noOfFreeNight) ||
                          noOfFreeNight == "") && (
                          <p
                            className="p_text error p_text_sm p_text_regular"
                            style={{ marginTop: "0.25rem" }}
                          >
                            {`*Please Enter Number of ${
                              (!notNullOrUndefined(noOfBookedNight) ||
                                noOfBookedNight == "") &&
                              (!notNullOrUndefined(noOfFreeNight) ||
                                noOfFreeNight == "")
                                ? "Booked Night & Free Night Value"
                                : !notNullOrUndefined(noOfBookedNight) ||
                                  noOfBookedNight == ""
                                ? "Booked Night"
                                : "Free Night Value"
                            }`}
                          </p>
                        )}
                    </div>

                    <div>
                      <HotelListingCustomDropDown
                        label={"Set Applicable Free Nights *"}
                        placeholder="Select Option"
                        dropdownStatus={applicableFreeNightDD}
                        onHandleDropDown={onHandleDropDown}
                        dropdownOptions={applicableFreeNightOptions}
                        style={{ marginBottom: "0.25rem" }}
                        onSelectDropDownOption={onSelectDropDownOption}
                        dropdownValue={
                          applicableFreeNight == null ? "" : applicableFreeNight
                        }
                        dropDownFor="Set Applicable Free Nights"
                        dropDownRef={applicableFreeNightDDRef}
                      />
                      {!isCreateScreen
                        ? notNullOrUndefined(applicableFreeNight) &&
                          isNotEqual(
                            getPromotionDetailsRes.data
                              .ob_pp_applicable_free_nights,
                            applicableFreeNight.value
                          ) && (
                            <p className="p_text error p_text_sm p_text_regular">
                              **Save pending, Click update to Save
                            </p>
                          )
                        : null}
                      {isCreateButtonClicked &&
                        !notNullOrUndefined(applicableFreeNight) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            *Please Set Applicable Free Nights
                          </p>
                        )}
                    </div>
                  </div>
                )
              ) : null}
            </div>

            {/* Step 2: Offer Restrictions */}
            <div className="cp_module_container" id={"2"}>
              <p className="custom-promotion-module-heading">
                Step 2: Offer Restrictions
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                  marginBottom: "1rem",
                }}
              >
                <div>
                  <HotelListingCustomDropDown
                    label={"Set Max Length of Stay *"}
                    placeholder="Select Option"
                    dropdownStatus={isSetMaxLengthStayDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={yesNoOptions}
                    style={{ marginBottom: "0.25rem" }}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      isSetMaxLengthStay == null ? "" : isSetMaxLengthStay
                    }
                    dropDownFor="Set Max Length of Stay"
                    dropDownRef={isSetMaxLengthStayDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {!isCreateScreen
                    ? notNullOrUndefined(isSetMaxLengthStay) &&
                      isNotEqual(
                        getPromotionDetailsRes.data.ob_pp_max_stay_days != null
                          ? 1
                          : 0,
                        isSetMaxLengthStay.value
                      ) &&
                      (notNullOrUndefined(maxStayDuration) &&
                      notNullOrUndefined(minStayDuration)
                        ? parseInt(maxStayDuration.value) >
                          parseInt(minStayDuration.value)
                        : true) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          **Save pending, Click update to Save
                        </p>
                      )
                    : null}
                  {isCreateButtonClicked &&
                    !notNullOrUndefined(isSetMaxLengthStay) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        *Please Set Max Length of Stay
                      </p>
                    )}
                </div>

                {notNullOrUndefined(isSetMaxLengthStay) &&
                isSetMaxLengthStay.value == 1 ? (
                  <div>
                    <HotelListingCustomDropDown
                      style={{ marginBottom: "0.25rem" }}
                      label={"Max Stay Duration for Discount *"}
                      placeholder="Select Option"
                      dropdownStatus={maxStayDurationDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={daysOption}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={
                        maxStayDuration == null ? "" : maxStayDuration
                      }
                      dropDownFor="Max Stay Duration for Discount"
                      dropDownRef={maxStayDurationDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(isSetMaxLengthStay) &&
                        notNullOrUndefined(maxStayDuration) &&
                        isNotEqual(
                          getPromotionDetailsRes.data.ob_pp_max_stay_days,
                          parseInt(maxStayDuration.value)
                        ) &&
                        (notNullOrUndefined(maxStayDuration) &&
                        notNullOrUndefined(minStayDuration)
                          ? parseInt(maxStayDuration.value) >
                            parseInt(minStayDuration.value)
                          : true) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(maxStayDuration) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Max Stay Duration for Discount
                        </p>
                      )}
                  </div>
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                  marginBottom: "1rem",
                }}
              >
                <div>
                  <HotelListingCustomDropDown
                    style={{ marginBottom: "0.25rem" }}
                    label={"Set Min Length of Stay *"}
                    placeholder="Select Option"
                    dropdownStatus={isSetMinLengthStayDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={yesNoOptions}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      isSetMinLengthStay == null ? "" : isSetMinLengthStay
                    }
                    dropDownFor="Set Min Length of Stay"
                    dropDownRef={isSetMinLengthStayDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {!isCreateScreen
                    ? (!notNullOrUndefined(isSetMinLengthStay) ||
                        isNotEqual(
                          getPromotionDetailsRes.data.ob_pp_min_stay_days !=
                            null
                            ? 1
                            : 0,
                          isSetMinLengthStay.value
                        )) &&
                      (notNullOrUndefined(maxStayDuration) &&
                      notNullOrUndefined(minStayDuration)
                        ? parseInt(maxStayDuration.value) >
                          parseInt(minStayDuration.value)
                        : true) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          **Save pending, Click update to Save
                        </p>
                      )
                    : null}
                  {isCreateButtonClicked &&
                    !notNullOrUndefined(isSetMinLengthStay) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        *Please Set Min Length of Stay
                      </p>
                    )}
                </div>

                {notNullOrUndefined(isSetMinLengthStay) &&
                isSetMinLengthStay.value == 1 ? (
                  <div>
                    <HotelListingCustomDropDown
                      style={{ marginBottom: "0.25rem" }}
                      label={"Min Stay Duration for Discount *"}
                      placeholder="Select Option"
                      dropdownStatus={minStayDurationDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={daysOption}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={
                        minStayDuration == null ? "" : minStayDuration
                      }
                      dropDownFor="Min Stay Duration for Discount"
                      dropDownRef={minStayDurationDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(minStayDuration) &&
                        isNotEqual(
                          getPromotionDetailsRes.data.ob_pp_min_stay_days,
                          parseInt(minStayDuration.value)
                        ) &&
                        (!notNullOrUndefined(maxStayDuration) ||
                          (notNullOrUndefined(maxStayDuration) &&
                          notNullOrUndefined(minStayDuration)
                            ? parseInt(maxStayDuration.value) >
                              parseInt(minStayDuration.value)
                            : true)) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(minStayDuration) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Min Stay Duration for Discount
                        </p>
                      )}
                  </div>
                ) : null}
              </div>
              {notNullOrUndefined(isSetMaxLengthStay) &&
              isSetMaxLengthStay.value == 1 &&
              notNullOrUndefined(isSetMinLengthStay) &&
              isSetMinLengthStay.value == 1 &&
              notNullOrUndefined(maxStayDuration) &&
              notNullOrUndefined(minStayDuration) &&
              parseInt(maxStayDuration.value) <=
                parseInt(minStayDuration.value) ? (
                <p className="p_text error p_text_sm p_text_regular">
                  Max stay duration should be greater than Min stay duration.
                </p>
              ) : null}
            </div>

            {/* Step 3:  Booking Window */}
            <div className="cp_module_container" id={"3"}>
              <p className="custom-promotion-module-heading">
                Step 3: Booking Window
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                  marginBottom: "1rem",
                }}
              >
                <div>
                  <HotelListingCustomDropDown
                    style={{ marginBottom: "0.25rem" }}
                    label={"Set Early Bird Rule *"}
                    placeholder="Select Option"
                    dropdownStatus={earlyBirdRuleDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={yesNoOptions}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={earlyBirdRule == null ? "" : earlyBirdRule}
                    dropDownFor="Set Early Bird Rule"
                    dropDownRef={earlyBirdRuleDDref}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {!isCreateScreen
                    ? notNullOrUndefined(earlyBirdRule) &&
                      isNotEqual(
                        getPromotionDetailsRes.data
                          .ob_pp_early_bird_rule_booking_window != null
                          ? 1
                          : 0,
                        earlyBirdRule.value
                      ) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          **Save pending, Click update to Save
                        </p>
                      )
                    : null}
                  {isCreateButtonClicked &&
                    !notNullOrUndefined(earlyBirdRule) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        *Please Set Early Bird Rule
                      </p>
                    )}
                </div>

                {notNullOrUndefined(earlyBirdRule) &&
                earlyBirdRule.value == 1 ? (
                  <div>
                    <HotelListingCustomDropDown
                      style={{ marginBottom: "0.25rem" }}
                      label={"Early Bird Booking Window *"}
                      placeholder="Select Option"
                      dropdownStatus={earlyBirdBookingWindowDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={daysOption}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={
                        earlyBirdBookingWindow == null
                          ? ""
                          : earlyBirdBookingWindow
                      }
                      dropDownFor="Early Bird Booking Window"
                      dropDownRef={earlyBirdBookingWindowDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(earlyBirdBookingWindow) &&
                        isNotEqual(
                          getPromotionDetailsRes.data
                            .ob_pp_early_bird_rule_booking_window,
                          parseInt(earlyBirdBookingWindow.value)
                        ) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(earlyBirdBookingWindow) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Early Bird Booking Window
                        </p>
                      )}
                  </div>
                ) : null}
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                  marginBottom: "1rem",
                }}
              >
                <div>
                  <HotelListingCustomDropDown
                    style={{ marginBottom: "0.25rem" }}
                    label={"Set Last Minute Deal Rule *"}
                    placeholder="Select Option"
                    dropdownStatus={lastMinDealRuleDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={yesNoOptions}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      lastMinDealRule == null ? "" : lastMinDealRule
                    }
                    dropDownFor="Set Last Minute Deal Rule"
                    dropDownRef={lastMinDealRuleDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {!isCreateScreen
                    ? notNullOrUndefined(lastMinDealRule) &&
                      isNotEqual(
                        getPromotionDetailsRes.data
                          .ob_pp_last_minute_deal_rule_booking_window != null
                          ? 1
                          : 0,
                        lastMinDealRule.value
                      ) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          **Save pending, Click update to Save
                        </p>
                      )
                    : null}
                  {isCreateButtonClicked &&
                    !notNullOrUndefined(lastMinDealRule) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        *Please Set Last Minute Deal Rule
                      </p>
                    )}
                </div>

                {notNullOrUndefined(lastMinDealRule) &&
                lastMinDealRule.value == 1 ? (
                  <div>
                    <HotelListingCustomDropDown
                      style={{ marginBottom: "0.25rem" }}
                      label={"Last Minute Booking Window *"}
                      placeholder="Select Option"
                      dropdownStatus={lastMinDealBookingWindowDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={daysOption.slice(0, 7)}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={
                        lastMinDealBookingWindow == null
                          ? ""
                          : lastMinDealBookingWindow
                      }
                      dropDownFor="Last Minute Booking Window"
                      dropDownRef={lastMinDealBookingWindowDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(lastMinDealBookingWindow) &&
                        isNotEqual(
                          getPromotionDetailsRes.data
                            .ob_pp_last_minute_deal_rule_booking_window,
                          parseInt(lastMinDealBookingWindow.value)
                        ) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(lastMinDealBookingWindow) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Last Minute Booking Window
                        </p>
                      )}
                  </div>
                ) : null}
              </div>
            </div>

            {/* Step 4:  Day Based Applicability */}
            <div className="cp_module_container" id={"4"}>
              <p className="custom-promotion-module-heading">
                Step 4: Day Based Applicability
              </p>

              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  gap: "1.25rem",
                  marginBottom: "0.5rem",
                }}
              >
                <HotelListingCustomDropDown
                  style={{ marginBottom: "0rem" }}
                  label={"Set Day Based Applicability *"}
                  placeholder="Select Option"
                  dropdownStatus={isDayBasedApplicabilityDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={yesNoOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={
                    isDayBasedApplicability == null
                      ? ""
                      : isDayBasedApplicability
                  }
                  dropDownFor="Set Day Based Applicability"
                  dropDownRef={isDayBasedApplicabilityDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />

                {notNullOrUndefined(isDayBasedApplicability) &&
                notNullOrUndefined(weekDays) &&
                isDayBasedApplicability.value == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {Object.keys(weekDays).map((day) => (
                      <div
                        onClick={() => addDays(day)}
                        className={`weekDayBox ${
                          notNullOrUndefined(applicableDays) &&
                          applicableDays.includes(day)
                            ? "selected"
                            : ""
                        }`}
                      >
                        <p
                          className="p_text p_text_sm p_text_bold"
                          style={{
                            cursor: "pointer",
                            color:
                              notNullOrUndefined(applicableDays) &&
                              applicableDays.includes(day)
                                ? "#fff"
                                : "",
                          }}
                        >
                          {day}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              {!isCreateScreen &&
                notNullOrUndefined(isDayBasedApplicability) &&
                isNotEqual(
                  getPromotionDetailsRes.data.day_based_applicability == null ||
                    getPromotionDetailsRes.data.day_based_applicability
                      .length == 0
                    ? 0
                    : 1,
                  isDayBasedApplicability.value
                ) &&
                notNullOrUndefined(applicableDays) &&
                !areValuesEqual(
                  getPromotionDetailsRes.data.day_based_applicability,
                  applicableDays
                ) && (
                  <p className="p_text error p_text_sm p_text_regular">
                    **Save pending, Click update to Save
                  </p>
                )}
              {isCreateButtonClicked ? (
                !notNullOrUndefined(isDayBasedApplicability) ? (
                  <p className="p_text error p_text_sm p_text_regular">
                    *Please Set Day Based Applicability
                  </p>
                ) : isDayBasedApplicability.value == 1 &&
                  (!notNullOrUndefined(applicableDays) ||
                    applicableDays.length == 0) ? (
                  <p className="p_text error p_text_sm p_text_regular">
                    *Please Select Atleast One Applicable Day
                  </p>
                ) : null
              ) : null}
            </div>

            {/* Step 5:  Promotion Dates */}
            <div className="cp_module_container" id={"5"}>
              <p className="custom-promotion-module-heading">
                Step 5: Promotion Dates
              </p>

              {isEditEnable ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <HotelListingCustomDropDown
                      style={{ marginBottom: "0.25rem" }}
                      label={"Applicable For"}
                      placeholder="Select Option"
                      dropdownStatus={applicableForDD}
                      onHandleDropDown={onHandleDropDown}
                      dropdownOptions={applicableForOptions}
                      onSelectDropDownOption={onSelectDropDownOption}
                      dropdownValue={applicableFor == null ? "" : applicableFor}
                      dropDownFor="Applicable For"
                      dropDownRef={applicableForDDRef}
                      // isUpdated={updateFieldObj.hotelCategory}
                    />
                    {!isCreateScreen
                      ? notNullOrUndefined(applicableFor) &&
                        isNotEqual(
                          notNullOrUndefined(
                            getPromotionDetailsRes.data
                              .ob_pp_stay_applicability_start
                          ) &&
                            notNullOrUndefined(
                              getPromotionDetailsRes.data
                                .ob_pp_booking_applicability_start
                            )
                            ? 2
                            : notNullOrUndefined(
                                getPromotionDetailsRes.data
                                  .ob_pp_stay_applicability_start
                              )
                            ? 0
                            : notNullOrUndefined(
                                getPromotionDetailsRes.data
                                  .ob_pp_booking_applicability_start
                              )
                            ? 1
                            : "",
                          applicableFor.value
                        ) && (
                          <p className="p_text error p_text_sm p_text_regular">
                            **Save pending, Click update to Save
                          </p>
                        )
                      : null}
                    {isCreateButtonClicked &&
                      !notNullOrUndefined(applicableFor) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          *Please Select Applicable For
                        </p>
                      )}
                  </div>

                  {notNullOrUndefined(applicableFor) && (
                    <div
                      className="cp_grey_box promotion_date"
                      style={{ marginTop: "1.25rem" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2rem",
                        }}
                      >
                        {applicableFor.value != 1 && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p className="p_text p_text_md p_text_bold">
                                Stay Date
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.4rem",
                                }}
                              >
                                <div
                                  onClick={() => handleToggle("Stay Date")}
                                  className={`toggle_switch_outer ${
                                    stayEndVisible ? "open" : ""
                                  }`}
                                  style={{
                                    marginLeft: "1.5rem",
                                    marginRight: "0.6rem",
                                  }}
                                >
                                  <div
                                    className={`toggle_switch_inner ${
                                      stayEndVisible ? "active" : ""
                                    }`}
                                  />
                                </div>
                                <p className="p_text p_text_sm p_text_bold">
                                  End Date
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                                alignItems: "center",
                                gap: "1.25rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.5rem",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="lease_expiry_calender">
                                  <div>
                                    <p className="hl_promotion_lable_cls">
                                      Start Date
                                    </p>
                                  </div>
                                </div>
                                <SingleDateCustomCalendar
                                  open={openStayStartDate}
                                  setOpen={setOpenStayStartDate}
                                  showCalendar={showCalendar}
                                  setShowCalendar={setShowCalender}
                                  date={stayStartDate}
                                  setDate={setStayStartDate}
                                  type="Start Date"
                                  restrictedPastDate={moment().subtract(2, "d")}
                                  isLoading={false}
                                />
                              </div>

                              {stayEndVisible && (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="lease_expiry_calender">
                                    <div>
                                      <p className="hl_promotion_lable_cls">
                                        End Date
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <SingleDateCustomCalendar
                                      open={openStayEndDate}
                                      setOpen={setOpenStayEndDate}
                                      showCalendar={showCalendar}
                                      setShowCalendar={setShowCalender}
                                      date={stayEndDate}
                                      setDate={setStayEndDate}
                                      type="End Date"
                                      isDisable={stayStartDate == null}
                                      restrictedPastDate={moment(stayStartDate)}
                                      isLoading={false}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            {!isCreateScreen &&
                              ((notNullOrUndefined(stayStartDate) &&
                                !isEqualDate(
                                  stayStartDate,
                                  getPromotionDetailsRes.data
                                    .ob_pp_stay_applicability_start
                                )) ||
                                (notNullOrUndefined(stayEndDate) &&
                                  !isEqualDate(
                                    stayEndDate,
                                    getPromotionDetailsRes.data
                                      .ob_pp_stay_applicability_end
                                  ))) && (
                                <p
                                  className="p_text error p_text_sm p_text_regular"
                                  style={{ marginTop: "0.5rem" }}
                                >
                                  {`**Save pending, Click update to Save ${
                                    notNullOrUndefined(stayStartDate) &&
                                    notNullOrUndefined(stayEndDate) &&
                                    !isEqualDate(
                                      stayStartDate,
                                      getPromotionDetailsRes.data
                                        .ob_pp_stay_applicability_start
                                    ) &&
                                    !isEqualDate(
                                      stayEndDate,
                                      getPromotionDetailsRes.data
                                        .ob_pp_stay_applicability_end
                                    )
                                      ? "Start Date & End Date"
                                      : notNullOrUndefined(stayEndDate) &&
                                        !isEqualDate(
                                          stayEndDate,
                                          getPromotionDetailsRes.data
                                            .ob_pp_stay_applicability_end
                                        )
                                      ? "End Date"
                                      : "Start Date"
                                  }`}
                                </p>
                              )}
                            {isCreateButtonClicked &&
                              (!notNullOrUndefined(stayStartDate) ||
                                stayStartDate == "") && (
                                <p
                                  className="p_text error p_text_sm p_text_regular"
                                  style={{
                                    marginTop: "0.25rem",
                                  }}
                                >
                                  *Please Select Stay Start Date
                                </p>
                              )}
                          </div>
                        )}
                        {applicableFor.value != 0 && (
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p className="p_text p_text_md p_text_bold">
                                Booking Date
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.4rem",
                                }}
                              >
                                <div
                                  onClick={() => handleToggle("Booking Date")}
                                  className={`toggle_switch_outer ${
                                    bookingEndDateVisible ? "open" : ""
                                  }`}
                                  style={{
                                    marginLeft: "1.5rem",
                                    marginRight: "0.6rem",
                                  }}
                                >
                                  <div
                                    className={`toggle_switch_inner ${
                                      bookingEndDateVisible ? "active" : ""
                                    }`}
                                  />
                                </div>
                                <p className="p_text p_text_sm p_text_bold">
                                  End Date
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                marginTop: "1rem",
                                display: "flex",
                                alignItems: "center",
                                gap: "1.25rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "0.5rem",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="lease_expiry_calender">
                                  <div>
                                    <p className="hl_promotion_lable_cls">
                                      Start Date
                                    </p>
                                  </div>
                                </div>
                                <SingleDateCustomCalendar
                                  open={openBookingStartDate}
                                  setOpen={setOpenBookingStartDate}
                                  showCalendar={showCalendar}
                                  setShowCalendar={setShowCalender}
                                  date={bookingStartDate}
                                  setDate={setBookingStartDate}
                                  type="Start Date"
                                  restrictedPastDate={moment().subtract(2, "d")}
                                  isLoading={false}
                                />
                              </div>

                              {bookingEndDateVisible && (
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="lease_expiry_calender">
                                    <div>
                                      <p className="hl_promotion_lable_cls">
                                        End Date
                                      </p>
                                    </div>
                                  </div>
                                  <SingleDateCustomCalendar
                                    open={openBookingEndDate}
                                    setOpen={setOpenBookingEndDate}
                                    showCalendar={showCalendar}
                                    setShowCalendar={setShowCalender}
                                    date={bookingEndDate}
                                    setDate={setBookingEndDate}
                                    type="End Date"
                                    isDisable={bookingStartDate == null}
                                    restrictedPastDate={moment(
                                      bookingStartDate
                                    )}
                                    isLoading={false}
                                  />
                                </div>
                              )}
                            </div>
                            {!isCreateScreen &&
                              ((notNullOrUndefined(bookingStartDate) &&
                                !isEqualDate(
                                  bookingStartDate,
                                  getPromotionDetailsRes.data
                                    .ob_pp_booking_applicability_start
                                )) ||
                                (notNullOrUndefined(bookingEndDate) &&
                                  !isEqualDate(
                                    bookingEndDate,
                                    getPromotionDetailsRes.data
                                      .ob_pp_booking_applicability_end
                                  ))) && (
                                <p
                                  className="p_text error p_text_sm p_text_regular"
                                  style={{ marginTop: "0.5rem" }}
                                >
                                  {`**Save pending, Click update to Save ${
                                    notNullOrUndefined(bookingEndDate) &&
                                    notNullOrUndefined(bookingStartDate) &&
                                    !isEqualDate(
                                      bookingStartDate,
                                      getPromotionDetailsRes.data
                                        .ob_pp_booking_applicability_start
                                    ) &&
                                    !isEqualDate(
                                      bookingEndDate,
                                      getPromotionDetailsRes.data
                                        .ob_pp_booking_applicability_end
                                    )
                                      ? "Start Date & End Date"
                                      : notNullOrUndefined(bookingEndDate) &&
                                        !isEqualDate(
                                          bookingEndDate,
                                          getPromotionDetailsRes.data
                                            .ob_pp_booking_applicability_end
                                        )
                                      ? "End Date"
                                      : "Start Date"
                                  }`}
                                </p>
                              )}
                            {isCreateButtonClicked &&
                              (!notNullOrUndefined(bookingStartDate) ||
                                bookingStartDate == "") && (
                                <p
                                  className="p_text error p_text_sm p_text_regular"
                                  style={{
                                    marginTop: "0.25rem",
                                  }}
                                >
                                  *Please Select Booking Start Date
                                </p>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="promotion-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20ZM11 5H9V7H11V5ZM11 9H9V15H11V9Z"
                        fill="#7DBF66"
                      />
                    </svg>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "1.5rem",
                      }}
                    >
                      <p className="p_text p_text_md p_text_regular">
                        This promotion will be active from the{" "}
                        <span style={{ fontWeight: "600" }}>Creation Date</span>{" "}
                        and has{" "}
                        <span style={{ fontWeight: "600" }}>No end Date</span>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.75rem",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p className="p_text p_text_sm p_text_regular">
                          Edit dates to change offer applicability
                        </p>
                        <button
                          className="cp-edit-btn"
                          onClick={() => setIsEditEnable(true)}
                        >
                          <Image src={adminImages.EditIcon} />
                          <p
                            className="p_text p_text_md p_text_bold"
                            style={{ color: "white", cursor: "pointer" }}
                          >
                            Edit
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>

                  {isCreateButtonClicked &&
                    !notNullOrUndefined(applicableFor) && (
                      <p
                        className="p_text error p_text_sm p_text_regular"
                        style={{
                          marginTop: "0.25rem",
                        }}
                      >
                        *Please Select Promotion Dates
                      </p>
                    )}
                </div>
              )}
            </div>

            {/* Step 6:  Promotion Settings */}
            <div className="cp_module_container" id={"6"}>
              <p className="custom-promotion-module-heading">
                Step 6: Promotion Settings
              </p>
              <div
                style={{
                  display: "flex",
                  gap: "1.25rem",
                }}
              >
                <div>
                  <HotelListingCustomDropDown
                    style={{ marginBottom: "0rem" }}
                    label={"Set Blackout Stay Dates *"}
                    placeholder="Select Option"
                    dropdownStatus={isBlackoutDatesDD}
                    onHandleDropDown={onHandleDropDown}
                    dropdownOptions={yesNoOptions}
                    onSelectDropDownOption={onSelectDropDownOption}
                    dropdownValue={
                      isBlackoutDates == null ? "" : isBlackoutDates
                    }
                    dropDownFor="Set Blackout Stay Dates"
                    dropDownRef={isBlackoutDatesDDRef}
                    // isUpdated={updateFieldObj.hotelCategory}
                  />
                  {isCreateButtonClicked &&
                    !notNullOrUndefined(isBlackoutDates) && (
                      <p
                        className="p_text error p_text_sm p_text_regular"
                        style={{
                          marginTop: "0.25rem",
                        }}
                      >
                        *Please Set Blackout Stay Dates
                      </p>
                    )}
                </div>
                {notNullOrUndefined(isBlackoutDates) &&
                  isBlackoutDates.value == 1 && (
                    <div>
                      <p
                        className="hl_promotion_lable_cls"
                        style={{ marginBottom: "" }}
                      >
                        Select Blackout Dates *
                      </p>
                      <MultipleDateCustomCalendar
                        dateBox={
                          <div
                            onClick={openBlackOutCalendar}
                            className="count_selected_backout_days_box"
                          >
                            <p
                              className="p_text p_text_sm p_text_bold"
                              style={{ cursor: "pointer" }}
                            >
                              {`${
                                notNullOrUndefined(selectedBlackOutDays)
                                  ? selectedBlackOutDays.length
                                  : 0
                              } Days Selected`}
                            </p>
                          </div>
                        }
                        open={openBlackoutDate}
                        setOpen={setOpenBlackoutDate}
                        showCalendar={showCalendar}
                        setShowCalendar={setShowCalender}
                        dates={selectedBlackOutDays}
                        setDates={setSelectedBlackoutDays}
                        restrictedPastDate={moment().subtract(2, "d")}
                        isLoading={false}
                      />
                      {isCreateButtonClicked &&
                        (!notNullOrUndefined(selectedBlackOutDays) ||
                          selectedBlackOutDays.length == 0) && (
                          <p
                            className="p_text error p_text_sm p_text_regular"
                            style={{
                              marginTop: "0.25rem",
                            }}
                          >
                            *Please Select Atleast One Date
                          </p>
                        )}
                    </div>
                  )}
              </div>
              <div style={{ marginBottom: "1.5rem" }}>
                {notNullOrUndefined(isBlackoutDates) &&
                  isBlackoutDates.value == 1 && (
                    <div className="selected_backout_date_container blackoutBoxScrollbar">
                      {notNullOrUndefined(selectedBlackOutDays) &&
                      selectedBlackOutDays.length != 0
                        ? selectedBlackOutDays.map((date) => (
                            <div className="selected_backout_date_box">
                              <p
                                className="p_text roboto p_text_sm p_text_bold"
                                style={{ color: "#fff" }}
                              >
                                {moment(date).format("DD MMM YY")}
                              </p>
                              <Image
                                src={adminImages.WhiteCross}
                                onClick={() => removeDate(date)}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          ))
                        : null}
                    </div>
                  )}
                {!isCreateScreen &&
                  notNullOrUndefined(selectedBlackOutDays) &&
                  notNullOrUndefined(
                    getPromotionDetailsRes.data.blackout_dates
                  ) &&
                  !areValuesEqual(
                    getPromotionDetailsRes.data.blackout_dates,
                    selectedBlackOutDays
                  ) && (
                    <p className="p_text error p_text_sm p_text_regular">
                      **Save pending, Click update to Save
                    </p>
                  )}
              </div>
              <div>
                <HotelListingCustomDropDown
                  style={{ marginBottom: "0.25rem", marginTop: "1rem" }}
                  label={"Set Refundability *"}
                  placeholder="Select Option"
                  dropdownStatus={refundabilityDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={refundabiltyOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={refundability == null ? "" : refundability}
                  dropDownFor="Set Refundability"
                  dropDownRef={refundabilityDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />
                {!isCreateScreen
                  ? notNullOrUndefined(refundability) &&
                    isNotEqual(
                      getPromotionDetailsRes.data.ob_pp_is_non_refundable,
                      refundability.value
                    ) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        **Save pending, Click update to Save
                      </p>
                    )
                  : null}
                {isCreateButtonClicked &&
                  !notNullOrUndefined(refundability) && (
                    <p className="p_text error p_text_sm p_text_regular">
                      *Please Set Refundability
                    </p>
                  )}
              </div>
              <div>
                <HotelListingCustomDropDown
                  style={{ marginBottom: "0.25rem", marginTop: "1rem" }}
                  label={"Rate Plan Applicability *"}
                  placeholder="Select Option"
                  dropdownStatus={ratePlanApplicabilityDD}
                  onHandleDropDown={onHandleDropDown}
                  dropdownOptions={applicabilityOptions}
                  onSelectDropDownOption={onSelectDropDownOption}
                  dropdownValue={
                    ratePlanApplicability == null ? "" : ratePlanApplicability
                  }
                  dropDownFor="Rate Plan Applicability"
                  dropDownRef={ratePlanApplicabilityDDRef}
                  // isUpdated={updateFieldObj.hotelCategory}
                />
                {!isCreateScreen
                  ? notNullOrUndefined(ratePlanApplicability) &&
                    notNullOrUndefined(
                      getPromotionDetailsRes.data.applicability
                    ) &&
                    isNotEqual(
                      getPromotionDetailsRes.data.applicability
                        .is_all_rate_plan_available,
                      ratePlanApplicability.value
                    ) && (
                      <p className="p_text error p_text_sm p_text_regular">
                        **Save pending, Click update to Save
                      </p>
                    )
                  : null}
                {isCreateButtonClicked &&
                  !notNullOrUndefined(ratePlanApplicability) && (
                    <p className="p_text error p_text_sm p_text_regular">
                      *Please Select Rate Plan Applicability
                    </p>
                  )}
              </div>

              {notNullOrUndefined(ratePlanApplicability) &&
                ratePlanApplicability.value == 0 &&
                (notNullOrUndefined(selectedRoomRatePlan) ? (
                  <div>
                    <div
                      className="cp_grey_box_rate_plan bottomScrollbar"
                      style={{
                        gridTemplateColumns: `repeat(${
                          Object.keys(selectedRoomRatePlan).length
                        },1fr)`,
                      }}
                    >
                      {Object.keys(selectedRoomRatePlan).map((key, i) => (
                        <div
                          className={`rate-plan-outer-container ${
                            i == Object.keys(selectedRoomRatePlan).length - 1
                              ? "no-border"
                              : ""
                          }`}
                        >
                          <div className="rate-room-container">
                            <div
                              style={{
                                height: "1.4375rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p className="p_text p_text_md p_text_bolder">
                                {key}
                              </p>
                            </div>
                            <div className="rates-div">
                              <div
                                onClick={() =>
                                  handleSelectAllRatePlanBox(
                                    key,
                                    notNullOrUndefined(selectedRoomRatePlan) &&
                                      isAllRatePlanSelected(
                                        selectedRoomRatePlan[key]
                                      )
                                  )
                                }
                                className={
                                  notNullOrUndefined(selectedRoomRatePlan) &&
                                  isAllRatePlanSelected(
                                    selectedRoomRatePlan[key]
                                  )
                                    ? "rate_checked_checkbox"
                                    : "rate_empty_check_box"
                                }
                              >
                                {notNullOrUndefined(selectedRoomRatePlan) &&
                                isAllRatePlanSelected(
                                  selectedRoomRatePlan[key]
                                ) ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="amenity_filled_check_box"
                                    viewBox="0 0 20 15"
                                    fill="none"
                                    style={{ width: "fit-content" }}
                                  >
                                    <path
                                      d="M1 9L6 13L18 2"
                                      stroke="white"
                                      strokeWidth="3"
                                    />
                                  </svg>
                                ) : null}
                              </div>
                              <p
                                className="p_text roboto p_text_sm p_text_regular"
                                onClick={() =>
                                  handleSelectAllRatePlanBox(
                                    key,
                                    notNullOrUndefined(selectedRoomRatePlan) &&
                                      isAllRatePlanSelected(
                                        selectedRoomRatePlan[key]
                                      )
                                  )
                                }
                                style={{
                                  color: "#818181",
                                  cursor: "pointer",
                                }}
                              >
                                Select all plans
                              </p>
                            </div>
                            <div className="rates-list-container">
                              {selectedRoomRatePlan[key].map((rate) => (
                                <div className="rates-div">
                                  <div
                                    onClick={() =>
                                      handleSingleRatePlanSelection(
                                        key,
                                        rate.rate_plan_name,
                                        rate.is_selected
                                      )
                                    }
                                    className={
                                      rate.is_selected == 1
                                        ? "rate_checked_checkbox"
                                        : "rate_empty_check_box"
                                    }
                                  >
                                    {rate.is_selected == 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="amenity_filled_check_box"
                                        viewBox="0 0 20 15"
                                        fill="none"
                                        style={{ width: "fit-content" }}
                                      >
                                        <path
                                          d="M1 9L6 13L18 2"
                                          stroke="white"
                                          strokeWidth="3"
                                        />
                                      </svg>
                                    ) : null}
                                  </div>

                                  <p
                                    onClick={() =>
                                      handleSingleRatePlanSelection(
                                        key,
                                        rate.rate_plan_name,
                                        rate.is_selected
                                      )
                                    }
                                    className="p_text roboto p_text_sm p_text_regular"
                                    style={{
                                      color: "#818181",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {rate.rate_plan_name}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {!isCreateScreen &&
                      notNullOrUndefined(selectedRoomRatePlan) &&
                      notNullOrUndefined(
                        getPromotionDetailsRes.data.applicability
                      ) &&
                      !areRatePlansEqual(
                        getPromotionDetailsRes.data.applicability.room_list,
                        selectedRoomRatePlan
                      ) && (
                        <p className="p_text error p_text_sm p_text_regular">
                          **Save pending, Click update to Save
                        </p>
                      )}
                    {isCreateButtonClicked &&
                      (!notNullOrUndefined(ratePlanIdArr) ||
                        ratePlanIdArr.length == 0) && (
                        <p
                          className="p_text error p_text_sm p_text_regular"
                          style={{ marginTop: "0.5rem" }}
                        >
                          *Please Select Atleast One Rate Plan
                        </p>
                      )}
                  </div>
                ) : (
                  <div
                    className="cp_grey_box rate_plan"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <p
                      className="p_text p_text_lg p_text_bold"
                      style={{ color: "#818181" }}
                    >
                      No Rate Plan listed
                    </p>
                  </div>
                ))}
            </div>

            {/* Step 7:  Promotion Expiry Date */}
            <div className="cp_module_container" id={"7"}>
              <p className="custom-promotion-module-heading">
                Step 7: Promotion Expiry Date
              </p>

              <div
                style={{
                  display: "flex",
                  gap: "0.75rem",
                  flexDirection: "column",
                }}
              >
                <div className="lease_expiry_calender">
                  <div style={{ marginBottom: "0.75rem" }}>
                    <p className="hl_promotion_lable_cls">
                      Select Expiry Date*
                    </p>
                  </div>
                </div>
                <div>
                  <SingleDateCustomCalendar
                    open={openExpiryDate}
                    setOpen={setOpenExpiryDate}
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalender}
                    date={expiryDate}
                    setDate={setExpiryDate}
                    type=""
                    restrictedPastDate={moment().subtract(2, "d")}
                    isLoading={false}
                  />
                  {!isCreateScreen &&
                    notNullOrUndefined(expiryDate) &&
                    !isEqualDate(
                      expiryDate,
                      getPromotionDetailsRes.data.ob_pp_end_date
                    ) && (
                      <p
                        className="p_text error p_text_sm p_text_regular"
                        style={{ marginTop: "0.25rem" }}
                      >
                        **If you update the expiry date, this promotion will
                        automatically deactivate; You need to activate it from Promotions list.
                       </p>
                    )}
                  {isCreateButtonClicked &&
                    (!notNullOrUndefined(expiryDate) || expiryDate == "") && (
                      <p
                        className="p_text error p_text_sm p_text_regular"
                        style={{ marginTop: "0.25rem" }}
                      >
                        *Please Select Expiry Date
                      </p>
                    )}
                </div>
              </div>
            </div>

            {/* Step 8:  Confirmation */}
            <div
              className="cp_module_container"
              id={"8"}
              style={{ border: "none", marginBottom: "6rem" }}
            >
              <p className="custom-promotion-module-heading">
                Step 8: Confirmation
              </p>

              <div>
                <p className="hl_promotion_lable_cls">
                  Enter Name of Promotion*{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "1.25rem",
                  }}
                >
                  <PromotionNameDD
                    value={
                      notNullOrUndefined(promotionName) ? promotionName : ""
                    }
                    dropdownOptions={dropdownNameOptions.filter(
                      (item) => item != null
                    )}
                    placeholder={"Enter Custom Name"}
                    onChange={handleInputChange}
                    inputFor={"Promotion Name"}
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    setValue={setPromotionName}
                  />
                  {/* <CustomInputField
                    value={
                      notNullOrUndefined(promotionName) ? promotionName : ""
                    }
                    className={"promotion-name-field"}
                    handleInputChange={handleInputChange}
                    placeholder={"Enter Promotion Name"}
                    label={"Enter Name of Promotion *"}
                    inputFor={"Promotion Name"}
                  /> */}

                  <Button
                    loading={btnLoading}
                    className={`cp_create-promotion-btn ${
                      props.match.params.id != "new" && disableUpdateBtn()
                        ? "disabled"
                        : ""
                    }`}
                    content={
                      props.match.params.id == "new"
                        ? "Create Promotion"
                        : "Update Promotion"
                    }
                    onClick={btnLoading ? null : handleCreateUpdateBtn}
                    disabled={
                      props.match.params.id == "new"
                        ? false
                        : btnLoading || disableUpdateBtn()
                    }
                  ></Button>
                </div>
                <p
                  className="p_text p_text_sm p_text_regular"
                  style={{ color: "#818181", marginTop: "1rem" }}
                >
                  Note: This name will appear on customer's end as Promotion
                  name
                </p>
                {!isCreateScreen &&
                  notNullOrUndefined(promotionName) &&
                  isNotEqual(
                    getPromotionDetailsRes.data.ob_pp_name,
                    promotionName
                  ) && (
                    <p
                      className="p_text error p_text_sm p_text_regular"
                      style={{ marginTop: "0.25rem" }}
                    >
                      **Save pending, Click update to Save
                    </p>
                  )}
                {isCreateButtonClicked &&
                  (!notNullOrUndefined(promotionName) ||
                    promotionName == "") && (
                    <p
                      className="p_text error p_text_sm p_text_regular"
                      style={{ marginTop: "0.25rem" }}
                    >
                      *Please Enter Promotion Name
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
