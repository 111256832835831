import React, { useState, useEffect } from "react";
import { useRef } from "react";

export default function PromotionNameDD(props) {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  let dropdownRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (dropdownStatus) {
        if (!dropdownRef.current.contains(e.target)) {
          setDropdownStatus(false);
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(()=>{
    if(!dropdownStatus){
      props.setErrorMsg("");
    }
  },[dropdownStatus])

  const onHandleDropDown = () => {
    setDropdownStatus(!dropdownStatus);
  };

  const onSelectDropDownOption = (option) =>{
    props.setValue(option);
    setDropdownStatus(false);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "2.5rem" }}>
      <div
        onClick={() => (dropdownStatus ? null : onHandleDropDown())}
        ref={dropdownRef}
        className={`promo_name_dropdown ${dropdownStatus ? "open" : ""}`}
        style={
          props.disabled
            ? { backgroundColor: "#f9f9f9", cursor: "not-allowed" }
            : {}
        }
      >
        <div
          onClick={() => (dropdownStatus ? null : onHandleDropDown())}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
        >
        
            {dropdownStatus ? (
              <div style={{display:"flex",flexDirection:"column"}}>
                <input
                  value={props.value}
                  placeholder={
                    props.value != null &&
                    props.value !== undefined &&
                    props.value != ""
                      ? props.value
                      : props.placeholder
                  }
                  className={`custom_promo_name_field ${
                    props.errorMsg != "" ? "error" : ""
                  }`}
                  style={{
                    color: dropdownStatus ? "#1c1c1c" : "#818181",
                  }}
                  onChange={(e) => props.onChange(e, props.inputFor)}
                />
                {props.errorMsg != "" ? (
                  <p
                    className="p_text roboto p_text_sm p_text_regular"
                    style={{ color: "#F73531",marginTop:"0.3rem" }}
                  >
                    {props.errorMsg}
                  </p>
                ) : null}
              </div>
            ) : (
              <p
                className="hl_dropdown_value_cls"
                style={{
                  color:
                    dropdownStatus ||
                    props.value === null ||
                    props.value == undefined ||
                    props.value === ""
                      ? "#818181"
                      : "#1c1c1c",
                  paddingLeft: dropdownStatus ? "0.625rem" : 0,
                }}
              >
                {props.value != null &&
                props.value !== undefined &&
                props.value != ""
                  ? props.value.length >= 21
                    ? props.value.slice(0, 21) + "..."
                    : props.value
                  : props.placeholder}
              </p>
            )}
        </div>

        {dropdownStatus ? (
          <>
            <p
              className="p_text roboto p_text_sm p_text_bold"
              style={{ color: "#818181", marginTop: "1.5rem" }}
            >
              Or select one
            </p>
            <div className="promo_name_dropdown_options_cls">
              {props.dropdownOptions.map((option) => {
                return (
                  <div
                    onClick={() => onSelectDropDownOption(option)}
                    className="promotion_name_dropdown_option"
                    key={option}
                  >
                    {option}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
