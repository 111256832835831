import React, { Component } from "react";
import { connect } from "react-redux";
import {
  API_RESPONSE,
  USER_DATA,
  SELECTED_HOTEL_ID,
  SELECTED_HOTEL_NAME,
} from "../constants";
import {
  actionResponseHandler,
  actionErrorHandler,
} from "../components/utils/responseHandler";
import {
  checkSessionStatus,
  clearSession,
} from "../redux/actions/session_actions";
import { browserName, isMobile } from "react-device-detect";
import { Loader } from "semantic-ui-react"

export default function (ComposedClass, isAuthRequired, adminRoute = null) {
  let storedValue = "";
  let routePermission = "";
  class AuthenticationCheck extends Component {
    state = {
      loading: true,
      userData: "",
      selectedHotelID: -1,
      selectedHotelName: "",
      intialRoute: [
        {
          permissions: "uep_system_administration",
          pathValue: "/system-admininstrator",
        },
        {
          permissions: "uep_hotel_profile",
          pathValue: "/profile",
        },
        {
          permissions: "uep_marketing",
          pathValue: "/coupon",
        },
        {
          permissions: "uep_a_site_user",
          pathValue: "/site-user",
        },
        {
          permissions: "uep_marketing",
          pathValue: "/seo",
        },
        {
          permissions: "uep_marketing",
          pathValue: "/marketing-campaign",
        },
        {
          permissions: "uep_analytics",
          pathValue: "/analytics",
        },
        {
          permissions: "uep_s3_bucket",
          pathValue: "/s3",
        },
        {
          permissions: "uep_admin_dashboard",
          pathValue: "/admin-dashboard",
        },
        {
          permissions: "uep_closed_hotels",
          pathValue: "/unlisted-hotels",
        },
        {
          permissions: "uep_hotel_listing",
          pathValue: "/unlisted-hotels",
        },
        {
          permissions: "uep_finance_inhouse",
          pathValue: "/finance-new",
        },
        ,
        {
          permissions: "uep_finance",
          pathValue: "/finance",
        },
        {
          permissions: "uep_inventories",
          pathValue: "/promotions",
        },

        {
          permissions: "uep_finance_inhouse",
          pathValue: "/hotel-balance-list",
        },
        {
          permissions: "uep_user_management",
          pathValue: "/user-permissions",
        },
        {
          permissions: "uep_activity_logs",
          pathValue: "/activity-logs",
        },
      ],
    };

    handleInitialRouting(object, value) {
      let sortPermissions = object;
      delete sortPermissions.uep_user_rate_access;
      delete sortPermissions.uep_view_bookings;
      delete sortPermissions.uep_inventories;
      delete sortPermissions.uep_amenities;
      delete sortPermissions.uep_add_manager;
      let initialPermissionName = Object.keys(sortPermissions).find(
        (key) => object[key] !== value
      );
      let pathValue = localStorage.getItem("path_name");
      storedValue = pathValue
        ? this.state.intialRoute.find((item) => item.pathValue === pathValue)
        : null;
      routePermission = storedValue ? storedValue.permissions : null;

      if (pathValue !== null && sortPermissions[routePermission] !== "0") {
        this.props.history.push(pathValue);
      } else if (sortPermissions.uep_hotel_profile !== value) {
        this.props.history.push("/profile");
      } else if (initialPermissionName) {
        let value = this.state.intialRoute.find(
          (item) => item.permissions === initialPermissionName
        );
        console.log("key", initialPermissionName);
        this.props.history.push(value.pathValue);
      } else {
        this.props.history.push("/permissions-denied");
      }
    }

    componentDidMount() {
      console.log("print ==>", this.props.match.url);
      let response = null;
      let userData = JSON.parse(localStorage.getItem(USER_DATA));
      let token = userData ? userData.token : null;
      if (token && token.length > 20) {
        let apiData = {
          login_user_id: userData.login_user_id,
          login_user_name: userData.login_user_name,
          login_user_type: userData.user_access_type,
        };
        this.props
          .dispatch(checkSessionStatus(apiData))
          .then(() => {
            response = this.props.session;
            if (response.status === API_RESPONSE.SUCCESS) {
              let policy_type = this.props.session.data.policy_type;
              localStorage.setItem(
                USER_DATA,
                JSON.stringify(this.props.session.data)
              );
              let userData = JSON.parse(localStorage.getItem(USER_DATA));

              if (userData && userData.hotels.length > 0) {
                let hotelName = userData.hotels.filter((item) => item.hotel_id === localStorage.getItem(SELECTED_HOTEL_ID))

                if (hotelName[0] !== undefined) localStorage.setItem(SELECTED_HOTEL_NAME, hotelName[0].hotel_name)
              }

              let permissions = this.props.session.data.permissions;

              if (!isAuthRequired) {
                this.props.history.push("/");
              }
              if (
                this.props.match.path === "/" &&
                permissions.uep_hotel_profile === "0"
              ) {
                // this.handleInitialRouting(permissions, "0");
                this.props.history.push("/permissions-denied");
              }

              if (
                this.props.match.path === "/profile" &&
                permissions.uep_hotel_profile === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/system-admininstrator" &&
                permissions.uep_system_administration === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/platform-charges" &&
                permissions.uep_system_administration === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/update-popularity" &&
                this.props.session.data.user_access_type != "SUPER_ADMIN"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/hotel-inventory" &&
                permissions.user_rate_access === "na"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/fullday-inventory" &&
                (permissions.uep_hotel_listing === "0" ||
                  permissions.uep_closed_hotels === "0")
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.path === "/user-access" &&
                permissions.uep_add_manager === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url.includes("/coupon") &&
                permissions.uep_coupon_management === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url.includes("/marketing-campaign") &&
                permissions.uep_marketing === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/site-user" &&
                permissions.uep_a_site_user === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                (this.props.match.url === "/admin-dashboard" ||
                  this.props.match.url === "/admin-dashboard-new") &&
                permissions.uep_admin_dashboard === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/property-search" &&
                permissions.uep_hotel_profile == "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/nearby-hotel-search" &&
                permissions.uep_admin_dashboard == "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/holida-dashboard" &&
                permissions.uep_admin_dashboard === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/unlisted-hotels" &&
                permissions.uep_hotel_listing === "0" &&
                permissions.uep_closed_hotels === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url.includes("/hotel-listing") &&
                (permissions.uep_hotel_listing === "0" ||
                  permissions.uep_closed_hotels === "0")
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/location" &&
                (permissions.uep_hotel_listing === "0" ||
                  permissions.uep_closed_hotels === "0")
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/room-category" &&
                (permissions.uep_hotel_listing === "0" ||
                  permissions.uep_closed_hotels === "0")
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                (this.props.match.url === "/promotions" ||
                  this.props.location.pathname.includes("/create-promotion")) &&
                permissions.uep_inventories === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/finance-new" &&
                permissions.uep_finance_inhouse === "0" &&
                permissions.uep_finance === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (this.props.match.url === "/property-accounts" &&
                permissions.uep_hotel_accounts === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/hotel-balance-list" &&
                permissions.uep_finance_inhouse === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/user-permissions" &&
                permissions.uep_user_management === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url === "/s3" &&
                permissions.uep_s3_bucket === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url.includes("/analytics") &&
                permissions.uep_analytics === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
              if (
                this.props.match.url.includes("/activity-logs") &&
                permissions.uep_activity_logs === "0"
              ) {
                this.props.history.push("/permissions-denied");
              }
            }
            else {
              if (token && token.length > 20) {
                console.log("error 1");
                this.props.history.push({
                  pathname: "/internal-server-error",
                  state: "No Token Exists"
                })
              }
              else {
                this.props.dispatch(clearSession());
                this.props.history.push("/login");
              }
            }
            this.setState({ loading: false });
          })
          .catch((error) => {
            console.log("__CatchBlockError__", error);

            this.setState({ loading: false });
            if (token && token.length > 20) {

              if (this.props?.session?.response?.data?.status === "SESSION_ERROR") {
                this.props.history.push({
                  pathname: "/internal-server-error",
                  state: error
                })
              }
            }
            else {
              this.props.dispatch(clearSession());
              this.props.history.push("/login");
            }

          });
      }
      else {
        this.setState({ loading: false });
        if (isAuthRequired) {
          this.props.history.push("/login");
        }
      }
      // this.props.dispatch(handlePropsforRouting());
    }

    render() {

      if (this.state.loading) {
        return (
          <div className="__loadingStateCls">

            <Loader active inline='centered' size="large" >
              Loading
            </Loader>

          </div>
        );
      }

      return (
        <div>
          <div
            className={
              this.props.location.pathname.includes("/login")
                ? "login_screen_container"
                : "admin_app_container"
            }
          >
            <ComposedClass {...this.props} userData={this.props.session} />
          </div>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      session: state.session.response,
    };
  };
  return connect(mapStateToProps)(AuthenticationCheck);
}