//Actual API endpoints
export const TYPE = {
  LOGIN: "admin-login",
  LOGOUT: "logout",
  CHANGE_PASSWORD: "change_password",
  CURRENT_RUNNING_BALANACE: "current_running_balance",
  GET_BOOKING_HISTORY: "get_booking_history",
  GET_PENDING_BOOKINGS: "get_pending_bookings",
  UPDATE_PAYMENT_HOLD: "update_payment_hold",
  UPDATE_CHECKIN_STATUS: "update_checkin_status",
  DENY_BOOKING: "deny_booking",
  GET_SOLD_OUT_DATES: "get_sold_out_dates",
  GET_SPECIFIC_SOLD_OUT_DATES: "get_specific_sold_out_dates",
  UPDATE_SOLD_OUT_DATE: "update_sold_out_date",
  GET_HOTEL_ROOMS: "get_hotel_rooms",
  GET_SPECIFIC_HOTEL_ROOMS: "get_specific_hotel_rooms",
  UPDATE_HOTEL_ROOMS: "update_hotel_rooms",
  GET_HOTEL_RATES: "get_hotel_rates",
  GET_SPECIFIC_HOTEL_RATES: "get_specific_hotel_rates",
  UPDATE_HOTEL_RATES: "update_hotel_rates",
  HOTEL_SOLD_OUT: "hotel_sold_out",
  HOTEL_UNSOLD_OUT: "hotel_bulk_unsold_out",
  GET_PROFILE_DATA: "get_profile_data",
  GET_ADMIN_BOOKINGS: "get_admin_bookings_by_filters",
  GET_BOOKING_COUNT: "get_admin_bookings_count",
  GET_BOOKING_ID_DETAILS: "get_admin_booking_id_details",
  REVERIFY_BOOKING_TRANSACTION: "reverify_booking_transactions", //PENDING BOOKINGS
  CANCEL_BOOKING: "cancel_booking",
  OTA_CANCEL_BOOKING: "cancel_ota_booking",
  RESEND_COMMUNICATIONS: "resend_communications",
  UPDATE_CANCEL_CHECKIN: "update_cancel_checkin",
  UPDATE_CHECKIN_COMPLETE: "update_checkin_complete",
  UPDATE_AMENTIES: "update_amenity",
  POLICY_AGREE_DENY: "policy_agree_deny",
  SA_GET_BASIC_SETTINGS: "get_basic_settings",
  SA_UPDATE_HOTEL_SETTINGS: "update_hotel_settings",
  AUTH: "auth",
  SESSION_VALID: "SESSION_VALID",
  CLEAR_SESSION: "CLEAR_SESSION",
  GET_HOTEL_CHECKIN: "get_hotel_checkin",
  GET_SPECIFIC_HOTEL_CHECKIN: "get_specific_hotel_checkin",
  UPDATE_HOTEL_CHECKIN: "update_hotel_checkin",
  VERIFY_OTP_LOGIN: "verify_otp_login",
  GET_ADMIN_HOTELS: "get_admin_hotels",
  GET_ALL_MANAGERS: "get_all_managers",
  EXTRANET_ADD_MANAGER: "extranet_add_manager",
  EXTRANET_REMOVE_MANAGER: "extranet_remove_manager",
  SEND_OTP_TO_CHANGE_PASSWORD: "send_otp_to_change_password",
  CHANGE_PASSWORD_USING_OTP: "change_password_using_otp",
  GET_STATES: "get_states",
  GET_REGIONS: "get_regions",
  CITIES_REGION: "cities-in-region",
  UPDATE_OR_INSERT_REGION: "insert-region",
  UPDATE_REGION: "update_region",
  LINK_CITIES_WITH_REGION: "link_cities_with_region",
  UNLINK_CITIES_WITH_REGION: "unlink_city_with_region",
  GET_CITIES: "get_cities",
  GET_AREA: "get_areas",
  ADD_NEW_CITY: "add_new_city",
  ADD_NEW_AREA: "add_new_area",
  SEARCH_ADMIN: "search_admin",
  LINK_ADMIN_WITH_HOTEL: "link_admin_with_hotel",
  UPDATE_HOTEL: "update_hotel",
  GET_HOTEL_DETAILS: "get_hotel_details",
  GET_UNLISTED_HOTELS: "get_unlisted_hotels",
  WELCOME: "welcome",
  UPDATE_NEARBY_AREAS: "update_nearby_areas",
  UPLOAD_HOTEL_IMAGES: "upload_hotel_images",
  UPLOAD_FULLDAY_DETAILS_IMG: "upload_fullday_details_img",
  BOOKING_TRANSFER: "booking_transfer",
  OTA_BOOKING_TRANSFER: "ota_booking_transfer",
  OTA_BOOKING_REFUND: "mark_ota_booking_refund",
  ADD_UPDATE_CANCELLATION_COMMENT: "add_update_cancellation_comment",
  GET_CLOSED_HOTELS: "get_closed_hotels",
  ADD_NEW_ACCOUNT: "add_new_account",
  SEARCH_ACCOUNT: "search_account",
  LINK_ACCOUNT: "link_account",
  UNLINK_ACCOUNT: "unlink_account",
  UPLOAD_REG_DOCS: "upload_reg_docs",
  LINKED_ACCOUNT_HOTEL_LIST: "linked_account_hotel_list",
  ADMIN: "admin",
  GST: "gst",
  HOTEL_LIST_FINANCE: "hotel_list_finance",
  INITIAL_ENTRY: "initial_entry",
  GET_INVOICES: "get_invoices",
  LEDGER: "ledger",
  LEDGER_PAY_TRANSACTIONS: "ledger_pay_transactions",
  CREDIT_DEBIT_NOTE: "credit_debit_note",
  GET_DISPUTES: "get_disputes",
  RESOLVE_DISPUTE: "resolve_dispute",
  CREATE_DISPUTE: "create_dispute",
  GET_CD_NOTES: "get_cd_notes",
  DOWNLOAD: "download",
  HOTEL_FINANCE_SUMMARY: "hotel_finance_summary",
  GET_SALES_DATA: "get_sales_data",
  GET_PACKAGE_BOOKING_HISTORY: "get_package_booking_history",
  GET_ADMIN_PACKAGE: "get_admin_package_by_filters",
  GET_PACKAGE_COUNT: "get_admin_package_count",
  RESEND_COMMUNICATIONS_PACKAGE: "resend_package_communications", //slim error, status code 500
  GET_PACKAGE_ID_DETAILs: "get_admin_package_id_details",
  UPDATE_PACKAGE_CANCEL_CHECKIN: "update_package_cancel_checkin",
  UPDATE_PACKAGE_CHECKIN_COMPLETE: "update_package_checkin_complete",
  CANCEL_PACKAGE: "cancel_package_booking",
  PACKAGE_CANCELLATION_COMMENT: "add_update_package_cancellation_comment",
  CREDIT_TRANSFER: "credit_transfer",
  HOTEL_PAYOUT: "hotel-payout",
  GET_EXTRANET_USER_BY_FILTERS: "get_extranet_user_by_filters",
  GET_USER_DETAILS: "get_user_details",
  UPDATE_USER_DETAILS: "update_user_details",
  CREATE_NEW_USER: "create_new_user",
  GET_USER_EXTRANET_PERMISSION_DETAILS: "get_user_extranet_permission_details",
  UPDTAE_USER_EXTRANET_PERMISSIONS: "update_user_extranet_permissions",
  LEDGER_PAY: "ledger-pay",
  // BOOKING_VOUCHER:'booking_voucher',
  ACTIVITY_LOGS: "activity_logs",
  GET_S3_BUCKETS: "bucket_list",
  GET_SPECIFIC_BUCKET: "specific_bucket_list",
  UPLOAD_BUCKET_IMAGES: "upload_bucket_images",
  UPLOAD_COUPON_IMAGES: "upload_coupon_images",
  HOTEL_UNSOLD_OUT: "hotel_bulk_unsold_out",
  COUPONS: {
    ENDPOINT: "coupons",
    PUT: "put_coupons",
    GET: "get_coupons",
    POST: "post_coupons",
  },
  SEO: {
    ENDPOINT: "seo",
    PUT: "put_seo",
    GET: "get_seo",
    POST: "post_seo",
    DELETE: "seo_delete",
  },
  SEO_CITIES: {
    ENDPOINT: "seo_cities",
    GET: "get_seo_cities",
  },
  SEO_CITY_CONTENT: {
    ENDPOINT: "seo_city_content",
    GET: "get_seo_city_content",
  },
  UPLOAD_SEO_IMAGE: {
    ENDPOINT: "upload_seo_image",
    POST: "post_upload_seo_image",
  },
  FAQS: {
    ENDPOINT: "faqs",
    PUT: "put_faqs",
    POST: "post_faqs",
  },
  DELETE_FAQ: {
    ENDPOINT: "delete_faq",
    POST: "delete_faq",
  },
  USER_DETAILS: {
    ENDPOINT: "user_details",
    GET: "get_user_details",
  },
  ANALYTICS_DATA: {
    CITY_HOTEL_ENDPOINT: "analytics-data",
    BALANCE_DETAILS: "balance-details",
    POST: "post_analytics_data",
    CARD_ENDPOINT: "bookings-count",
    GET: "get_card_data",
    PAYMENT_MODE_CHANGE: "update-pay-mode",
  },
  WALLET_TRANSACTIONS: {
    ENDPOINT: "get_user_wallet_transactions",
    GET: "get_user_wallet_transactions",
  },
  USER_BY_MOBILE: {
    ENDPOINT: "get_user_by_mobile",
    GET: "get_user_by_mobile",
  },
  EXPERIENCES_LIST: {
    ENDPOINT: "experiences",
    POST: "post_experiences",
  },
  EDIT_EXPERIENCES_DETAILS: {
    ENDPOINT: "experience_details",
    POST: "post_experiences_details",
  },
  CREATE_PACKAGE: {
    ENDPOINT: "experience",
    POST: "post_experiences",
  },
  UPDATE_EXPERIENCES: {
    ENDPOINT: "experience",
    PUT: "put_experience",
  },
  HOTELS_POPULARITY_LIST: "hotels_popularity_list",
  DELETE_USER: {
    ENDPOINT: "delete_user",
    PUT: "delete_user",
  },

  BULK_POPULARITY_UPDATE: {
    ENDPOINT: "bulk_update_popularity",
    PUT: "bulk_update_popularity",
  },
  CITIES_AND_HOTELS_LIST: {
    ENDPOINT: "cities_and_hotels_data",
    GET: "get_cities_and_hotels_data",
  },
  CAMPAIGNS_LIST: {
    ENDPOINT: "campaigns",
    POST: "post_campaigns",
  },
  EDIT_CAMPAIGN: {
    ENDPOINT: "edit_campaign",
    POST: "post_campaign",
  },
  CREATE_CAMPAIGN: {
    ENDPOINT: "create_campaign",
    POST: "create_campaign",
  },
  ADD_GENERAL_COMMENT: {
    ENDPOINT: "add_general_comment",
    POST: "post_add_general_comment",
  },
  COUPON_MAIL: {
    ENDPOINT: "coupon-mail",
    POST: "coupon-mail",
  },
  CREATE_TRC_COUPON: {
    ENDPOINT: "create-trc-coupon",
    POST: "post_create-trc-coupon",
  },
  GET_COMMISSIONS_DATA: {
    ENDPOINT: "get_hotel_commission",
    GET: "get_hotel_commission",
  },
  UPDATE_COMMISSIONS: {
    ENDPOINT: "update_hotel_commission",
    POST: "update_hotel_commission",
  },
  GET_ACTIVITY_TYPE: {
    ENDPOINT: "get-activity-type",
    POST: "get-activity-type",
  },
  SEARCH_COUPON: "search_coupon",
  GET_REFUND_POLICIES: "get_refund_policies",
  GET_HOTEL_REFUND_POLICIES: {
    ENDPOINT: "get_hotel_refund_policies",
    POST: "get_hotel_refund_policies",
  },
  UPDATE_BULK_HOTEL_REFUND_POLICY: {
    ENDPOINT: "update_refund_policy",
    POST: "update_refund_policy"
  },
  INSERT_ESCALATION_DETAILS: "insert_escalation_details",
  GET_ESCALATION_DETAILS: "get_escalation_details",
  ADD_COMMENT: "add_comment",
  GET_REVIEW: "get-review",
  POST_REVIEW: "post-review",
  DELETE_EXTRANET_USER: "delete_extranet_user",
  FETCH_CITIES: "fetch_cities",
  GET_USER_REVIEWS_FOR_HOTEL: "get_user_reviews_for_hotel",
  GET_GENERAL_COMMENT: "get_general_comment",
  CREATE_ROOM: "create_room_category",
  GET_ROOM_CATEGORY_DATA: "get_room_category_data",
  GET_ROOM_IMAGES: "get_room_images",
  UPDATE_ROOM_CATEGORY: "update_room_category",
  GET_RATE_PLAN: "get_rate_plan",
  CREATE_RATE_PLAN: "create_rate_plan",
  UPDATE_RATE_PLAN: "update_rate_plan",
  UPDATE_PROPERTY_CHECKIN_CHECKOUT: "update_property_checkin_checkout",
  GET_PROPERTY_AMENITIES: "get_property_amenities",
  ADD_REMOVE_PROPERTY_AMENITIES: "add_remove_property_amenities",
  GET_ROOM_AMENITIES: "get_room_amenities",
  ADD_REMOVE_ROOM_AMENITIES: "add_remove_room_amenities",
  UPLOAD_ROOM_CATEGORY_IMAGES: "upload_room_category_img",
  GET_FULL_DAY_ROOMS: "get-full-day-rooms",
  EDIT_FULL_DAY_ROOMS: "edit-full-day-rooms",
  BULK_EDIT_FULL_DAY: "bulk-edit-full-day",
  EDIT_HOTEL_INFO: "edit_hotel_info",
  GET_HOLIDA_ADMIN_BOOKINGS_BY_FILTERS: "get_holida_admin_bookings_by_filters",
  GET_HOLIDA_ADMIN_BOOKING_ID_DETAILS: "get_holida_admin_booking_id_details",
  RESEND_HOLIDA_COMMUNICATIONS: "resend_holida_communications",
  UPDATE_HOLIDA_CHEKIN_COMPLETE: "update_holida_checkin_complete",
  UPDATE_HOLIDA_CANCEL_CHECKIN: "update_holida_cancel_checkin",
  GET_SOURCES: "get_sources",
  GET_CACHE_AND_SECRET: "get-cache-and-secret",
  CLEAR_CACHE: "clear-cache",
  HOTEL_HOLIDA_POPULARITY_LIST: "hotels_holida_popularity_list",
  BULK_UPDATE_HOLIDA_POPULARITY: "bulk_update_holida_popularity",
  GET_USER_TRANSACTION: "get_user_transactions",
  EDIT_CASHBACK_CONFIG: "edit-cashback-config",
  GET_CASHBACK_CONFIG: "get-cashback-config",
  GET_ROOM_PLAN_REFUND_POLICY: "get_room_plan_refund_policy",
  DOWNLOAD_BOOKING_DATA_ADMIN: "download_booking_data_admin",
  DOWNLOAD_ANALYTICS_DATA: "download-analytics-data",
  GET_SOLD_OUT_HOTELS: "get_sold_out_hotels",
  GET_HOTELS_ANALYTICS: "get_hotels_analytics",
  CANCEL_HOLIDA_BOOKING: "cancel_holida_booking",
  REFUND_HOLIDA_BOOKING: "refund_holida_booking",
  CREATE_TICKET_RESOLUTION_COUPON: "create-trc-coupon",
  HOLIDA_CREDIT_TRANSFER: "holida_credit_transfer",
  CANCEL_DAY_BOOKING: "cancel_day_booking",
  NEAREST_HOTEL: "nearest-hotel",
  SWITCH_CHANNEL_MANAGER_GLOBAL_STATUS: "switch_channel_manager_global_status",
  LINK_CHANNEL_MANAGER: "link_channel_manager",
  UNLINK_CHANNEL_MANAGER: "unlink_channel_manager",
  SWITCH_PROPERTY_CHANNEL_MANAGER: "switch_property_channel_manager",
  FETCH_CM_PROPERTY_DATA: "fetch_cm_property_data",
  FETCH_CM_INVENTORIES_DATA: "fetch_cm_inventories_data",
  CREATE_ADDITIONAL_POLICY: "create_additional_policy",
  UPDATE_ADDITIONAL_POLICY: "update_additional_policy",
  REMOVE_ADDITIONAL_POLICY: "remove_additional_policy",
  GET_HOLIDA_CITIES: "get-holida-cities",
  GET_HOLIDA_SEO: "get-holida-seo",
  CREATE_HOLIDA_SEO: "create-holida-seo",
  GET_HOLIDA_SEO_CITY_CONTENT: "get-holida-seo-city-content",
  UPDATE_HOLIDA_SEO_CITY_CONTENT: "update-holida-seo-city-content",
  CREATE_HOLIDA_FAQ: "create-holida-faq",
  UPDATE_HOLIDA_FAQ: "update-holida-faq",
  DELETE_HOLIDA_SEO: "delete-holida-seo",
  DELETE_HOLIDA_FAQ: "delete-holida-faq",
  UPLOAD_HOLIDA_SEO_IMAGE: "upload-holida-seo-image",
  CREATE_UPDATE_CRON_DETAILS: "create-update-cron-details",
  GET_CRON_DETAILS: "get-cron-details",
  GET_CRON_LOGS: "get-cron-logs",
  GET_PAYMENT_COMMENT: "get_payment_comment",
  GET_STATUS_COMMENT: "get_status_comment",
  GET_TCS_PERCENTAGE: "get-tcs-percentage",
  EDIT_TCS_PERCENTAGE: "edit-tcs-percentage",
  GET_CONTACT_NUMBER_AND_MSG: "get-contact-number-and-msg",
  EDIT_CONTACT_NUMBER_AND_MSG: "edit-contact-number-and-msg",
  PROCESS_PENDING_TRANSACTION: "process_pending_transaction",
  EDIT_CITY_INFO: "edit_city_info",
  ADD_HOLIDA_BOOKING_COMMENT: "add_holida_booking_comment",
  GET_HOLIDA_BOOKING_COMMENTS: "get_holida_booking_comments",
  POST_HOLIDA_REVIEW: "post-holida-review",
  GET_HOLIDA_REVIEW: "get-holida-review",
  GET_PLATFORM_CHARGE_AND_STATUS: "get_platform_charges_and_status",
  UPDATE_PLATFORM_CHARGE_STATUS: "update_platform_charge_status",
  UPDATE_PLATFORM_CHARGE_DATA: "update_platform_charge_data",
  GET_BOOKING_CM_LOGS: "get_booking_cm_logs",
  VERIFY_PAN: "verify-pan",
  VERIFY_TAN: "verify-tan",
  PENDING_REQUESTS: "pending-requests",
  APPROVE_REJECT: "approve-reject-request",
  GET_HOTEL_DOCS_URL: "get-hotel-docs-url",
  ADMIN_HOME: "admin-home",
  UPDATE_PLATFORM_CHARGE_DATA: "update_platform_charge_data",
  GET_SOURCES: "get_sources",
  GET_CACHE_AND_SECRET: "get-cache-and-secret",
  CLEAR_CACHE: "clear-cache",
  GET_PROMOTIONS: "get_promotions",
  EDIT_PROMOTIONS: "edit_promotions",
  GET_PROMOTIONS_ID_DETAILS: "get_promotion_id_details",
  INSERT_PROMOTION: "insert_promotion",
  UPDATE_PROMOTION: "update_promotion",
  ADMIN_HOME: "admin-home",
  UPLOAD_CHEQUE: "upload_cheque",
  GET_OTA_LIST: "get_ota_list",
  UPDATE_OTA_STATUS: "update_ota_status"
};


